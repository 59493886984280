import { NgModule } from '@angular/core';
import { CheckResultModule } from '../../module/check-result/check-result.module';
import { TraceResultModule } from '../../module/trace-result/trace-result.module';
import { RecycleResultModule } from '../../module/recycle-result/recycle-result.module';
import { PurchaseResultModule } from '../../module/purchase-result/purchase-result.module';
import { CollationResultModule } from '../../module/collation-result/collation-result.module';
import { ProgressModule } from '../../module/progress/progress.module';
import { UserDataModule } from '../../module/user-data/user-data.module';
import { UploadModule } from '../../module/upload/upload/upload.module';
import { ItemUploadModule } from '../../module/upload/item-upload/item-upload.module';
import { MessageUploadModule } from '../../module/upload/message-upload/message-upload.module';
import { RecycleUploadModule } from '../../module/upload/recycle-upload/recycle-upload.module';
import { ItemListModule } from '../../module/item-list/item-list.module';
import { MessageListModule } from '../../module/message-list/message-list.module';
import { ContentRoutingModule } from '../../router/content-routing/content-routing.module';

import { HeaderComponent } from '../../component/header/header.component';
import { ContentHeaderComponent } from '../../component/content-header/content-header.component';
import { SidenavComponent } from '../../component/sidenav/sidenav.component';
import { IndexComponent } from './index.component';

import { TitleService } from '../../service/title.service';

@NgModule({
  imports: [
    ContentRoutingModule,
    ProgressModule,
    CheckResultModule,
    TraceResultModule,
    RecycleResultModule,
    PurchaseResultModule,
    CollationResultModule,
    UserDataModule,
    UploadModule,
    ItemUploadModule,
    MessageUploadModule,
    RecycleUploadModule,
    ItemListModule,
    MessageListModule,
  ],
  exports: [
    ContentRoutingModule,
    ProgressModule,
    CheckResultModule,
    TraceResultModule,
    RecycleResultModule,
    PurchaseResultModule,
    CollationResultModule,
    UserDataModule,
    IndexComponent,
    UploadModule,
    ItemUploadModule,
    MessageUploadModule,
    RecycleUploadModule,
    ItemListModule,
    MessageListModule,
  ],
  providers: [],
  declarations: [
    HeaderComponent,
    ContentHeaderComponent,
    SidenavComponent,
    IndexComponent
  ]
})
export class IndexModule { }
