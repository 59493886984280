import { NgModule } from '@angular/core';
import { SharedModule } from '../../module/shared/shared.module';

import { WorkProgressTableComponent } from '../../component/table/work-progress-table/work-progress-table.component';
import { ProgressComponent } from './progress.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RequestInterceptor } from '../../service/request.service';
import { WorkProgressSearchFormComponent } from 'src/app/component/search-form/work-progress-search-form/work-progress-search-form.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    ProgressComponent,
    WorkProgressSearchFormComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    }
  ],
  declarations: [
    WorkProgressTableComponent,
    ProgressComponent,
    WorkProgressSearchFormComponent
  ]
})
export class ProgressModule { }
