import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { PurchaseQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class PurchaseSearchFormService {
  purchaseSearchFormParamsSubject$: BehaviorSubject<PurchaseQueryInfo>;
  purchaseSearchFormParams$: Observable<PurchaseQueryInfo>;
  now: Date = new Date();

  constructor() {
    this.purchaseSearchFormParamsSubject$ = new BehaviorSubject({
      slip_number: '',
      arrival_start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      arrival_end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      item_code: '',
      serial_number: '',
      user_id: '',
      start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString()
    });
    this.purchaseSearchFormParams$ = this.purchaseSearchFormParamsSubject$.asObservable();
  }

  setPurchaseFormParams(searchInfo: PurchaseQueryInfo): void {
    this.purchaseSearchFormParamsSubject$.next(searchInfo);
  }
}
