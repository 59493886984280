import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { UserFormInit } from '../../interface/interface';

@Injectable({
  providedIn: 'root'
})
export class FormService {
  userFormInitSubject$: BehaviorSubject<UserFormInit>;
  userFormInit$: Observable<UserFormInit>;
  userFormFlg: boolean;
  userFormInitParams: UserFormInit;
  constructor() {
    this.userFormInitSubject$ = new BehaviorSubject(
      {
        formFlg: false,
        user_id: '',
        name: '',
        auth_id: '',
        company: ''
      }
    );
    this.userFormInit$ = this.userFormInitSubject$.asObservable();
  }
/**
 * @param row userDataTableの1record分
 * @param formType string: updateだとアップデート用のフォームを生成するフラグに変換(formFlg = true) それ以外の文字列はインサート用のフォームを生成するフラグに変換
 */
  setUserFormInit(row, formFlg): void {
    this.userFormInitParams = {
      formFlg: formFlg,
      user_id: row.user_id,
      name: row.name,
      auth_id: row.auth_id || 'User',
      company: row.company
    };
    this.userFormInitSubject$.next(this.userFormInitParams);
  }
}
