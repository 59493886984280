import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { PurchaseResult } from '../../../../interface/result.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, MatDialog, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { PurchaseSearchFormService } from '../../../service/search-form/purchase-search-form.service';
import { PurchaseDialogService } from '../../../service/search-form/purchase-dialog.service';
import { PurchaseItemDialogComponent } from '../../dialog/purchase-form-dialog/purchase-form-dialog.component';
import { filter, debounceTime, skip, flatMap, tap, publish, refCount } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';
import { PurchaseItem } from 'src/interface/interface';

@Component({
  selector: 'app-purchase-result-table',
  templateUrl: './purchase-result-table.component.html',
  styleUrls: ['./purchase-result-table.component.css']
})
export class PurchaseResultTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  columns: string[];
  casheData: PurchaseResult[];
  dataSource: MatTableDataSource<PurchaseResult>;
  now: Date = new Date();

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public matDialog: MatDialog,
    private requestService: RequestService,
    private titleService: TitleService,
    private purchaseDialogService: PurchaseDialogService,
    private purchaseSearchFormService: PurchaseSearchFormService,
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.purchaseSearchFormService.purchaseSearchFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/purchase/row/result', query))
        )
        .subscribe(
          res => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
          console.log(res)
          },
          error => {
            console.log(`シリアルナンバー記録履歴テーブル生成 : ${error}`);
          },
          () => {
            console.log('シリアルナンバー記録履歴テーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('シリアルナンバー記録履歴');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  update(row: PurchaseItem): void {
    const arrivalDate = new Date(row.arrival_date);
    this.purchaseDialogService.setPurchaseDialogInit(
      {
        id: row.id,
        slip_number: row.slip_number,
        supplier_name: row.supplier_name,
        arrival_date: arrivalDate.getFullYear() + '/' + String(arrivalDate.getMonth() + 1) + '/' + arrivalDate.getDate(),
        item_code: row.item_code,
        serial_number: row.serial_number,
        remarks: row.remarks
      });
    const dialog = this.matDialog.open(PurchaseItemDialogComponent,
      {
        'height': '75vh',
        'width': '50vw',
        'disableClose': false
      }
    );
    const purchaseItem$ = dialog.afterClosed()
      .pipe(
        tap(result => console.log(result)),
        publish(),
        refCount()
      );
    this.subscriptions.push(
      purchaseItem$
        .pipe(
          filter(result => result),
          tap(),
          flatMap(result => this.requestService.patch('/purchase/item', result, new HttpHeaders({ 'Content-Type': 'application/json' })))
        )
        .subscribe(
          res => {
            if (res.length !== 0) {
              alert('更新が完了しました');
              const serachInfo = this.purchaseSearchFormService.purchaseSearchFormParamsSubject$.getValue();
              this.purchaseSearchFormService.setPurchaseFormParams(serachInfo);
            }
          }
        )
    );
  }

  delete(targetId: string): void {
    var confirmResult = confirm('削除してよろしいですか？');

    if(confirmResult){
      this.requestService.delete('/purchase/item/' + targetId, {}, new HttpHeaders({ 'Content-Type': 'application/json' }))
      .subscribe(
        res => {
          if (res.length !== 0) {
            alert('削除が完了しました');
            const serachInfo = this.purchaseSearchFormService.purchaseSearchFormParamsSubject$.getValue();
            this.purchaseSearchFormService.setPurchaseFormParams(serachInfo);
          }
        })
    }else{
      console.log('not');
    }
  }

  private setColumns(): void {
    this.columns = [
      'slip_number',
      'arrival_date',
      'supplier_name',
      'item_code',
      'serial_number',
      'remarks',
      'user_id',
      'user_name',
      'created_at',
      'button'
    ];
  }
}
