import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { CollationResult } from '../../../../interface/result.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, MatDialog, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { CollationSearchFormService } from '../../../service/search-form/collation-search-form.service';
import { HttpHeaders } from '@angular/common/http';
import { filter, flatMap, tap, publish, refCount } from 'rxjs/operators';
import { CollationItem } from 'src/interface/interface';
import { CollationDialogService } from 'src/app/service/collation-dialog.service';
import { CollationDialogComponent } from '../../dialog/collation-form-dialog/collation-form-dialog.component';

@Component({
  selector: 'app-collation-result-table',
  templateUrl: './collation-result-table.component.html',
  styleUrls: ['./collation-result-table.component.css']
})
export class CollationResultTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  columns: string[];
  casheData: CollationResult[];
  dataSource: MatTableDataSource<CollationResult>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public matDialog: MatDialog,
    private requestService: RequestService,
    private titleService: TitleService,
    private dialogService: CollationDialogService,
    private searchFormSevice: CollationSearchFormService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.searchFormSevice.searchFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/collation/list', query))
        )
        .subscribe(
          res => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
          console.log(res)
          },
          error => {
            console.log(`照合結果テーブル生成 : ${error}`);
          },
          () => {
            console.log('照合結果テーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('照合結果履歴');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  update(row: CollationItem): void {
    this.dialogService.setCollationDialogInit(
      {
        id: row.id,
        store_name: row.store_name,
        item_code: row.item_code,
        item_count: row.item_count
      }
    );
    const dialog = this.matDialog.open(CollationDialogComponent,
      {
        'height': '75vh',
        'width': '50vw',
        'disableClose': false
      }
    );
    const collationItem$ = dialog.afterClosed()
      .pipe(
        tap(result => console.log(result)),
        publish(),
        refCount()
      );
    this.subscriptions.push(
      collationItem$
        .pipe(
          filter(result => result),
          tap(),
          flatMap(result => this.requestService.patch('/collation/item', result, new HttpHeaders({ 'Content-Type': 'application/json' })))
        )
        .subscribe(
          res => {
            if (res.length === 0) {
              alert('更新が完了しました');
              console.log('更新： 成功');
              this.searchFormSevice.repeat();
            } else {
              console.log('更新: 失敗');
            }
          }
        )
    )
  }

  delete(id: string): void {
    var confirmResult = confirm('削除してよろしいですか？');
    if (confirmResult) {
      this.requestService.delete('/collation/item', {id: id}, new HttpHeaders({ 'Content-Type': 'application/json' }))
      .subscribe(
        res => {
          if (res.length === 0) {
            alert('削除が完了しました');
            console.log('削除: 成功')
            this.searchFormSevice.repeat();
          } else {
            console.log('削除: 失敗')
          }
        }
      )
    } else {
      console.log('not');
    }
  }

  private setColumns(): void {
    this.columns = [
      'date',
      'time',
      'store_name',
      'item_code',
      'item_count',
      'alert_message',
      'user_name',
      'button'
    ];
  }
}
