import { NgModule } from '@angular/core';
import { MessageListComponent } from './message-list.component';
import { SharedModule } from '../shared/shared.module';
import { MessageTableComponent } from '../../component/table/message-table/message-table.component';
import { MessageSearchFormComponent } from '../../component/search-form/message-search-form/message-search-form.component';
import { ContentRoutingModule } from '../../router/content-routing/content-routing.module';

@NgModule({
  imports: [
    SharedModule,
    ContentRoutingModule
  ],
  exports: [
    SharedModule,
    MessageTableComponent,
    MessageSearchFormComponent,
    MessageListComponent,
    ContentRoutingModule,
  ],
  declarations: [
    MessageTableComponent,
    MessageSearchFormComponent,
    MessageListComponent,
  ]
})
export class MessageListModule { }
