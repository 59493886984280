import { NgModule } from '@angular/core';
import { PurchaseResultComponent } from './purchase-result.component';
import { SharedModule } from '../shared/shared.module';
import { PurchaseResultTableComponent } from '../../component/table/purchase-result-table/purchase-result-table.component'
import { PurchaseSearchFormComponent } from '../../component/search-form/purchase-search-form/purchase-search-form.component';
import { PurchaseItemDialogComponent } from '../../component/dialog/purchase-form-dialog/purchase-form-dialog.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    PurchaseResultComponent,
    PurchaseSearchFormComponent,
    PurchaseResultTableComponent
  ],
  providers: [],
  declarations: [
    PurchaseResultTableComponent,
    PurchaseResultComponent,
    PurchaseSearchFormComponent,
    PurchaseItemDialogComponent
  ],
  entryComponents: [
    PurchaseItemDialogComponent
  ]
})
export class PurchaseResultModule { }
