import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatTableDataSource, MatSort } from '@angular/material';
import { Progress } from '../../../../interface/interface';
import { RequestService } from '../../../service/request.service';
import { TitleService } from '../../../service/title.service';
import { Subscription, pipe } from 'rxjs';
import { filter, debounceTime, flatMap } from 'rxjs/operators';
import { WorkProgressSearchFormService } from 'src/app/service/search-form/work-progress-search-form.service';

@Component({
  selector: 'app-work-progress-table',
  templateUrl: './work-progress-table.component.html',
  styleUrls: ['./work-progress-table.component.css']
})
export class WorkProgressTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  cacheData: Progress[];
  dataSource: MatTableDataSource<Progress>;
  columns: string[];

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private searchFormService: WorkProgressSearchFormService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.searchFormService.searchFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/progress', query))
        )
        .subscribe(
          res => {
            this.cacheData = Array.isArray(res) ?res : [];
            this.dataSource = new MatTableDataSource(this.cacheData)
            this.dataSource.sort = this.sort;
            console.log(res);
          },
          error => {
            console.log(`バッチ別作業進捗テーブル生成 : ${error}`);
          },
          () => {
            console.log('バッチ別作業進捗テーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('バッチ別作業進捗');
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setColumns(): void {
    this.columns = [
      'batch_no',
      'imported_on',
      'started_at',
      'finished_at',
      'direction_count',
      'direction_count_rate',
      'item_row_count',
      'item_row_count_rate',
      'item_qty',
      'item_qty_rate'
    ];
  }
}
