import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { RecycleResult } from '../../../../interface/result.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { RecycleSearchFormService } from '../../../service/search-form/recycle-search-form.service';
import { subscribeOn, debounceTime, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-recycle-result-table',
  templateUrl: './recycle-result-table.component.html',
  styleUrls: ['./recycle-result-table.component.css']
})
export class RecycleResultTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  columns: string[];;
  casheData: RecycleResult[];
  dataSource: MatTableDataSource<RecycleResult>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private recycleSearchFormSevice: RecycleSearchFormService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.recycleSearchFormSevice.recycleSearchFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/recycle/row/result', query))
        )
        .subscribe(
          res => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
          console.log(res)
          },
          error => {
            console.log(`リサイクル券登録履歴テーブル生成 : ${error}`);
          },
          () => {
            console.log('リサイクル券登録履歴テーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('リサイクル券記録履歴');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setColumns(): void {
    this.columns = [
      'direction_no',
      'recycle_no',
      'user_id',
      'user_name',
      'created_at'
    ];
  }
}
