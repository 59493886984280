import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ItemSearchInfo } from '../../../../interface/search.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { ItemFormService } from '../../../service/search-form/item-form.service';
import { subscribeOn, debounceTime, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-item-table',
  templateUrl: './item-table.component.html',
  styleUrls: ['./item-table.component.css']
})
export class ItemTableComponent implements OnInit {
  subscriptions: Subscription[];
  columns: string[];
  itemInfo: ItemSearchInfo;
  casheData: ItemSearchInfo[];
  dataSource: MatTableDataSource<ItemSearchInfo>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private itemFormSevice: ItemFormService
    ) { }

  ngOnInit() {    
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.itemFormSevice.itemFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/check/item/row/result/', query))
        )
        .subscribe(
          res => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
          },
          error => {
            console.log(`商品マスタテーブル生成 : ${error}`);
          },
          () => {
            console.log('商品マスタテーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('商品マスタ情報一覧');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setColumns(): void {
    this.columns = [
      'customer_cd',
      'customer_name',
      'item_cd',
      'item_name',
      'jancode',
      'trace_flg',
      'created_at'
    ];
  }
}
