import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CollationDialogService } from '../../../service/collation-dialog.service';
import { CollationItem } from '../../../../interface/interface';

@Component({
  // angularMaterialでdialog表示する際selector名を変更すると表示がおかしくなるので注意
  selector: 'app-collation-dialog',
  templateUrl: './collation-form-dialog.component.html',
  styleUrls: ['./collation-form-dialog.component.css']
})

/**
 * CollationTableComponentから呼び出し
 * 照合情報を入力するフォームダイアログ
 * dialogを閉じた時,closeメソッドの引数が登録データとしてCollationTableComponentに渡される
 */
export class CollationDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  date: Date;

  isLinear: boolean;
  storeNameGroup: FormGroup;
  itemCodeGroup: FormGroup;
  itemCountGroup: FormGroup;
  collationItem: CollationItem;
  hide: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<CollationDialogComponent>,
    private formBuilder: FormBuilder,
    private formService: CollationDialogService
  ) {}

  ngOnInit() {
    this.hide = true;
    this.subscriptions = [];
    this.isLinear = false;
    this.date = new Date();
    // init
    this.collationItem = this.formService.collationDialogInitSubject$.getValue();

    this.storeNameGroup = this.formBuilder.group({
      store_name: this.formBuilder.control(
        this.collationItem.store_name, Validators.compose([
          Validators.required,
          Validators.maxLength(256)
        ]
      ))
    });
    this.itemCodeGroup = this.formBuilder.group({
      item_code: this.formBuilder.control(
        this.collationItem.item_code, Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z_¥-¥.]*$/),
          Validators.maxLength(30)
        ]
      ))
    });
    this.itemCountGroup = this.formBuilder.group({
      item_count: this.formBuilder.control(
        this.collationItem.item_count, Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9.]*$/),
        ]
      ))
    });

    // 各入力値を監視
    this.subscriptions.push(
        this.storeNameGroup.get('store_name').valueChanges
        .subscribe((value) => {
          this.collationItem['store_name'] = value;
        }),
        this.itemCodeGroup.get('item_code').valueChanges
        .subscribe((value) => {
          this.collationItem['item_code'] = value;
        }),
        this.itemCountGroup.get('item_count').valueChanges
        .subscribe((value) => {
          this.collationItem['item_count'] = value;
        }),
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  // キャンセルボタン押下の場合情報を何も返さない
  cancelDialog() {
    this.matDialogRef.close();
  }

  // OKボタン押下で入力された情報を返す
  okDialog() {
    this.matDialogRef.close(
      this.collationItem
    );
  }
}
