import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { PurchaseDialogService } from '../../../service/search-form/purchase-dialog.service';
import { PurchaseItem } from '../../../../interface/interface';

@Component({
  // angularMaterialでdialog表示する際selector名を変更すると表示がおかしくなるので注意
  selector: 'app-purchase-dialog',
  templateUrl: './purchase-form-dialog.component.html',
  styleUrls: ['./purchase-form-dialog.component.css']
})

/**
 * PurchaseTableComponentから呼び出し
 * シリアルナンバー記録情報を入力するフォームダイアログ
 * dialogを閉じた時,closeメソッドの引数が登録データとしてUserTableComponentに渡される
 */
export class PurchaseItemDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  date: Date;

  isLinear: boolean;
  slipNumberGroup: FormGroup;
  arrivalDateGroup: FormGroup;
  supplierNameGroup: FormGroup;
  itemCodeGroup: FormGroup;
  serialNumberGroup: FormGroup;
  remarksGroup: FormGroup;
  purchaseItem: PurchaseItem;
  hide: boolean;
  headerDisabled: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<PurchaseItemDialogComponent>,
    private formBuilder: FormBuilder,
    private formService: PurchaseDialogService
  ) {}

  ngOnInit() {
    this.hide = true;
    this.subscriptions = [];
    this.isLinear = false;
    this.date = new Date();
    // init
    this.purchaseItem = this.formService.purchaseDialogInitSubject$.getValue();

    if (this.purchaseItem.slip_number == "") {
      this.headerDisabled = false
    } else {
      this.headerDisabled = true
    }

    this.slipNumberGroup = this.formBuilder.group({
      slip_number: this.formBuilder.control(
        { value: this.purchaseItem.slip_number, disabled: this.headerDisabled},
        Validators.compose([
          Validators.required,
          Validators.maxLength(256)
        ]
      ))
    });
    this.arrivalDateGroup = this.formBuilder.group({
      arrival_date: this.formBuilder.control(
        { value: new Date(this.purchaseItem.arrival_date), disabled: this.headerDisabled},
        Validators.compose([
          Validators.required
        ]
      ))
    });
    this.supplierNameGroup = this.formBuilder.group({
      supplier_name: this.formBuilder.control(

        { value: this.purchaseItem.supplier_name, disabled: this.headerDisabled},
         Validators.compose([
          Validators.required,
          Validators.maxLength(30)
        ]
      ))
    });
    this.itemCodeGroup = this.formBuilder.group({
      item_code: this.formBuilder.control(this.purchaseItem.item_code, Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z_¥-¥.]*$/),
          Validators.maxLength(30)
        ]
      ))
    });
    this.serialNumberGroup = this.formBuilder.group({
      serial_number: this.formBuilder.control(
        this.purchaseItem.serial_number, Validators.compose([
          Validators.maxLength(256)
        ]
      ))
    });
    this.remarksGroup = this.formBuilder.group({
      remarks: this.formBuilder.control(
        this.purchaseItem.remarks, Validators.compose([
          Validators.maxLength(256)
        ]
      ))
    });

    // 各入力値を監視
    this.subscriptions.push(
        this.slipNumberGroup.get('slip_number').valueChanges
        .subscribe((value) => {
          this.purchaseItem['slip_number'] = value;
        }),
        this.arrivalDateGroup.get('arrival_date').valueChanges
          .subscribe((value) => {
            if(value != null){
              this.purchaseItem['arrival_date'] = value.getFullYear() + '/' + String(value.getMonth() + 1) + '/' + value.getDate()
            }
          }),
        this.supplierNameGroup.get('supplier_name').valueChanges
        .subscribe((value) => {
          this.purchaseItem['supplier_name'] = value;
        }),
        this.itemCodeGroup.get('item_code').valueChanges
        .subscribe((value) => {
          this.purchaseItem['item_code'] = value;
        }),
        this.serialNumberGroup.get('serial_number').valueChanges
        .subscribe((value) => {
          this.purchaseItem['serial_number'] = value;
        }),
        this.remarksGroup.get('remarks').valueChanges
          .subscribe((value) => {
            this.purchaseItem['remarks'] = value;
          })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  // キャンセルボタン押下の場合情報を何も返さない
  cancelDialog() {
    this.matDialogRef.close();
  }

  // OKボタン押下で入力された情報を返す
  okDialog() {
    this.matDialogRef.close(
      this.purchaseItem
    );
  }
}
