import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../service/title.service';

@Component({
  selector: 'app-reload',
  templateUrl: './reload.component.html',
  styleUrls: ['./reload.component.css']
})
export class ReloadComponent implements OnInit {
  date: Date;

  constructor(
    private titleService: TitleService
  ) { }

  ngOnInit() {
    this.getNowDate();
  }

  getNowDate() {
    this. date = new Date();
  }
  reload() {
    this.getNowDate();
    this.titleService.setTitle(this.titleService.title);
  }
}
