import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SearchInfo } from '../../../../interface/search.interface';
import { CheckResult } from '../../../../interface/result.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { SearchFormService } from '../../../service/search-form/search-form.service';
import { subscribeOn, debounceTime, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-check-result-table',
  templateUrl: './check-result-table.component.html',
  styleUrls: ['./check-result-table.component.css']
})
export class CheckResultTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  columns: string[];
  searchInfo: SearchInfo;
  casheData: CheckResult[];
  dataSource: MatTableDataSource<CheckResult>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private searchFormSevice: SearchFormService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.searchFormSevice.searchFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/check/row/result', query))
        )
        .subscribe(
          res => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
          console.log(res)
          },
          error => {
            console.log(`検品結果テーブル生成 : ${error}`);
          },
          () => {
            console.log('検品結果テーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('検品結果');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setColumns(): void {
    this.columns = [
      'batch_no',
      'direction_no',
      'item_cd',
      'item_name',
      'qty_checked',
      'input_flg',
      'user_id',
      'user_name',
      'updated_at',
      'started_at',
      'finished_at'
    ];
  }
}
