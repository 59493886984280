import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { SpinnerComponent } from '../../component/spinner/spinner.component';
import { ReloadComponent } from '../../component/reload/reload.component';


const modules = [
  BrowserAnimationsModule,
  CommonModule,
  AngularMaterialModule,
  HttpClientModule,
  ReactiveFormsModule
];

@NgModule({
  imports: [modules],
  exports: [
    modules,
    SpinnerComponent,
    ReloadComponent
  ],
  declarations: [
    SpinnerComponent,
    ReloadComponent
  ],
  providers: [
  ],
  entryComponents: [
    SpinnerComponent
  ]
})
export class SharedModule { }
