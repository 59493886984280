import { NgModule } from '@angular/core';
import { CheckResultComponent } from './check-result.component';
import { SharedModule } from '../shared/shared.module';
import { CheckResultTableComponent } from '../../component/table/check-result-table/check-result-table.component';
import { SearchFormComponent } from '../../component/search-form/check-search-form/search-form.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    CheckResultComponent,
    SearchFormComponent,
    CheckResultTableComponent
  ],
  providers: [],
  declarations: [
    CheckResultTableComponent,
    CheckResultComponent,
    SearchFormComponent
  ]
})
export class CheckResultModule { }
