import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { LoginUser, User } from '../../interface/interface';
import { UserInfoService } from './user-info.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  userInfo: LoginUser;

  constructor(
    private router: Router,
    private userInfoService: UserInfoService,
  ) {
  }

  canActivate( next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (document.cookie) {
      return true;
    }
    this.router.navigateByUrl('');
    return false;
  }
}
