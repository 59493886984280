import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { IndexModule } from './module/index/index.module';
import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './component/page-not-found/page-not-found.component';
import { LoginModule } from './module/login/login.module';
import { CookieService } from 'ngx-cookie-service';
import { CompareValidatorDirective } from './compare-validator.directive';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    CompareValidatorDirective,
  ],
  imports: [
    IndexModule,
    LoginModule,
    CommonModule
  ],
  providers: [
    CookieService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
