import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ItemQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class ItemFormService {
  itemFormParamsSubject$: BehaviorSubject<ItemQueryInfo>;
  itemFormParams$: Observable<ItemQueryInfo>;
  itemInfo: ItemQueryInfo;

  constructor() { 
    this.itemInfo = {
      customer_cd: '',
      item_cd: '',
      jancode: ''
    };
    this.itemFormParamsSubject$ = new BehaviorSubject(this.itemInfo);
    this.itemFormParams$ = this.itemFormParamsSubject$.asObservable();
  }

  setItemFormParams(itemInfo: ItemQueryInfo): void {
    this.itemInfo.customer_cd = itemInfo.customer_cd;
    this.itemInfo.item_cd = itemInfo.item_cd;
    this.itemInfo.jancode = itemInfo.jancode;
    this.itemFormParamsSubject$.next(this.itemInfo);
  }
}
