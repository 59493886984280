import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatFormFieldControl, DateAdapter, MatDatepicker, NativeDateAdapter, MatCheckbox, MatCheckboxChange } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CollationSearchInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip, flatMap, tap, take } from 'rxjs/operators';
import { Observable, Subscription, pipe } from 'rxjs';
import { CollationSearchFormService } from '../../../service/search-form/collation-search-form.service';
import { TitleService } from '../../../service/title.service';
import { RequestService } from '../../../../app/service/request.service';
import { CollationResultCsv } from '../../../../interface/result.interface';
import * as iconv from 'iconv-lite';

export class MyDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    const dateNames: string[] = [];
    for (let i = 0; i < 31; i++) {
      dateNames[i] = String(i + 1);
    }
    return dateNames;
  }
}

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-collation-search-form',
  templateUrl: './collation-search-form.component.html',
  styleUrls: ['./collation-search-form.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter}
  ]
})

export class CollationSearchFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  formGroup: FormGroup;
  searchInfo: CollationSearchInfo;
  date: Date;
  fromDate: Date;
  toDate: Date;
  minDate: Date;
  fromMaxDate: Date;
  maxDate: Date;
  csvQuery: string;
  isChecked: Boolean = true;

  constructor(
    private requestService: RequestService,
    private searchFormService: CollationSearchFormService,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private titleService: TitleService
  ) {}


  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title  => title === '照合結果履歴'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.searchFormService.setSearchFormParams(this.searchInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('照合結果履歴検索設定：成功');
          }
        )
    );

    this.subscriptions.push(
      this.searchFormService.csvEvent$
        .pipe(
          skip(1),
          flatMap(query => this.requestService.get('/collation/list/csv', query))
        )
        .subscribe(
          res => {
            const date = new Date();
            const formatted = `${date.getFullYear()}${(date.getMonth()+1).toString().padStart(2, '0')}${date.getDate().toString().padStart(2, '0')}_${date.getHours().toString().padStart(2, '0')}${date.getMinutes().toString().padStart(2, '0')}`.replace(/\n|\r/g, '');
            var csvData: Array<Array<CollationResultCsv>> = [];
            // csvを一つにするか、店舗ごとに分割するかの判定
            if (this.isChecked) {
              csvData.push(res);
            } else {
              // 店舗名ごとにデータを分ける(店舗名でソートされている前提)
              var storeData: Array<CollationResultCsv> = [];
              var checkStoreName = '';
              res.forEach((element: CollationResultCsv) => {
                if (checkStoreName === element.store_name) {
                  storeData.push(element);
                } else {
                  if (storeData.length !== 0) {
                    // 配列の値渡し
                    csvData.push(storeData.slice());
                    storeData.length = 0;
                  } 
                  storeData.push(element);
                  checkStoreName = element.store_name;                
                }
              });
              if (storeData.length !== 0) {
                csvData.push(storeData.slice());
              }
            }
            // csvダウンロード
            csvData.forEach((data) => {
              const csvShiftJis = iconv.encode(this.jsonToCsv(data), 'Shift-JIS');
              const blob = new Blob([csvShiftJis], { type: 'text/csv;charset=shift-jis;' });
              const url = window.URL.createObjectURL(blob);
              const a = document.createElement('a');
              a.href = url;
              a.download = this.isChecked ? `${formatted}.csv` : `${formatted}_${data[0].store_name}.csv`;
              a.click();
              a.remove();
            });
          },
          error => {
            console.log(`CSVデータ取得 : ${error}`);
          },
          () => {
            console.log('CSVデータ取得 : 成功');
          }
        )
      );
    
    Object.keys(this.searchInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            if (key === 'fromDate') {
              this.fromDate = value;
                if (this.formGroup.get('fromDate').value > this.formGroup.get('toDate').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('fromDate').setValue(this.toDate);
                  this.formGroup.get('toDate').setValue(this.fromDate);
                  this.searchInfo.from_date = this.toDate;
                  this.searchInfo.to_date = this.fromDate;
                } else {
                  this.fromMaxDate = this.formGroup.get('toDate').value;
                  this.searchInfo.from_date = this.formGroup.get('fromDate').value;
                  this.searchInfo.to_date = this.formGroup.get('toDate').value;
                }
            }
            if (key === 'toDate') {
              this.toDate = value;
                if (this.formGroup.get('fromDate').value > this.formGroup.get('toDate').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('fromDate').setValue(this.toDate);
                  this.formGroup.get('toDate').setValue(this.fromDate);
                  this.searchInfo.from_date = this.toDate;
                  this.searchInfo.to_date = this.fromDate;
                } else {
                  this.fromMaxDate = this.formGroup.get('toDate').value;
                  this.searchInfo.from_date = this.formGroup.get('fromDate').value;
                  this.searchInfo.to_date = this.formGroup.get('toDate').value;
                }
            }
            this.searchInfo[key] = value;
          })
      );
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.searchInfo = {
      store_name: '',
      item_code: '',
      user_name: '',
      from_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()),
      to_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate())
    };
    this.search();
  }

  search() {
    this.searchFormService.setSearchFormParams(this.searchInfo);
  }

  download() {
    this.searchFormService.startDownload();
  }

  setIsChecked(event: MatCheckboxChange): void {
    this.isChecked = event.checked;
  }

  private fieldInit() {
    this.subscriptions = [];
    this.date = new Date();
    this.fromDate = new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.toDate = new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 90);
    this.maxDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    this.fromMaxDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    this.dateAdapter.setLocale('ja');
    this.searchInfo = {
      store_name: '',
      item_code: '',
      user_name: '',
      from_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()),
      to_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate())
    };
    this.formGroup = this.formBuilder.group({
      store_name: this.formBuilder.control(''),
      item_code: this.formBuilder.control(''),
      user_name: this.formBuilder.control(''),
      from_date: this.formBuilder.control({
        value: this.fromDate,
        disabled: true
      }),
      to_date: this.formBuilder.control({
        value: this.toDate,
        disabled: true
      })
    });
  }

  private jsonToCsv(json) {
    return json.map(function(d){
      return Object.keys(d).map(function(key) {
        return d[key];
      }).join(',');
    }).join("\n");
  }
}

