import { Injectable } from '@angular/core';
import { LoginUser } from '../../interface/interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {
  userInfoSubject$: BehaviorSubject<LoginUser>;
  userInfo$: Observable<LoginUser>;

  constructor() {
    this.userInfoSubject$ = new BehaviorSubject({
      user_id: '',
      name: '',
      company_code: '',
      company_name: '',
      auth_id: '',
      scope: '',
      cloud_batch_progress: false,
      cloud_direction_upload: false, 
      cloud_items_upload: false, 
      cloud_check_result: false,
      cloud_serial_detail: false, 
      cloud_recycle_result: false,
      cloud_recycle_upload: false,
      cloud_purchase_result: false,
      cloud_purchase_regist: false,
      cloud_user_master: false,
      cloud_collation: false
    });
    this.userInfo$ = this.userInfoSubject$.asObservable();
  }

  setUserInfo(userInfo: LoginUser): void {
    this.userInfoSubject$.next(userInfo);
  }
}
