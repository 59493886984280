import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MessageQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class MessageFormService {
  messageFormParamsSubject$: BehaviorSubject<MessageQueryInfo>;
  messageFormParams$: Observable<MessageQueryInfo>;
  messageInfo: MessageQueryInfo;

  constructor() {
    this.messageInfo = {
      jancode: '',
      alert_message: ''
    };
    this.messageFormParamsSubject$ = new BehaviorSubject(this.messageInfo);
    this.messageFormParams$ = this.messageFormParamsSubject$.asObservable();
  }

  setMessageFormParams(messageInfo: MessageQueryInfo): void {
    this.messageInfo.jancode = messageInfo.jancode;
    this.messageInfo.alert_message = messageInfo.alert_message;
    this.messageFormParamsSubject$.next(this.messageInfo);
  }
}
