import { NgModule } from '@angular/core';
import { CollationResultComponent } from './collation-result.component';
import { SharedModule } from '../shared/shared.module';
import { CollationSearchFormComponent } from '../../component/search-form/collation-search-form/collation-search-form.component';
import { CollationResultTableComponent } from '../../component/table/collation-result-table/collation-result-table.component';
import { CollationDialogComponent } from 'src/app/component/dialog/collation-form-dialog/collation-form-dialog.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    CollationResultComponent,
    CollationSearchFormComponent,
    CollationResultTableComponent
  ],
  providers: [],
  declarations: [
    CollationResultComponent,
    CollationSearchFormComponent,
    CollationResultTableComponent,
    CollationDialogComponent
  ],
  entryComponents: [
    CollationDialogComponent
  ]
})
export class CollationResultModule { }
