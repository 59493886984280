import { Component, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { MatFormFieldControl } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ItemQueryInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip } from 'rxjs/operators';
import { Observable, Subscription, pipe } from 'rxjs';
import { ItemFormService } from '../../../service/search-form/item-form.service';
import { TitleService } from '../../../service/title.service';

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-item-search-form',
  templateUrl: './item-search-form.component.html',
  styleUrls: ['./item-search-form.component.css']
})
export class ItemSearchFormComponent implements OnInit, OnDestroy{
  subscriptions: Subscription[];
  formGroup: FormGroup;
  itemInfo: ItemQueryInfo;
  
  csvQuery: string;

  constructor(
    private itemFormService: ItemFormService,
    private formBuilder: FormBuilder,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title  => title === '商品マスタ一覧'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.itemFormService.setItemFormParams(this.itemInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('商品マスタ一覧初期化：成功');
          }
        )
    );
    
    Object.keys(this.itemInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            this.itemInfo[key] = value;
          })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.itemInfo = {
      customer_cd: '',
      item_cd: '',
      jancode: ''
    };
    this.search();
  }

  search() {
    this.itemFormService.setItemFormParams(this.itemInfo);
  }

  private fieldInit() {
    this.subscriptions = [];
    this.itemInfo = {
      customer_cd: '',
      item_cd: '',
      jancode: ''
    };

    this.formGroup = this.formBuilder.group({
      customer_cd: this.formBuilder.control(''),
      item_cd: this.formBuilder.control(''),
      jancode: this.formBuilder.control('')
    });
  }
}
