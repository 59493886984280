import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { WorkProgressSearchInfo, WorkProgressQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class WorkProgressSearchFormService {
  searchFormParamsSubject$: BehaviorSubject<WorkProgressQueryInfo>;
  searchFormParams$: Observable<WorkProgressQueryInfo>;
  searchInfo: WorkProgressQueryInfo;
  date: Date;

  constructor() {
    this.date = new Date();
    this.searchInfo = {
      shop_code: '',
      start_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString(),
      end_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString()
    };
    this.searchFormParamsSubject$ = new BehaviorSubject(this.searchInfo);
    this.searchFormParams$ = this.searchFormParamsSubject$.asObservable();
  }

  setSearchFormParams(searchInfo: WorkProgressSearchInfo): void {
    this.searchInfo.shop_code = searchInfo.shop_code;
    this.searchInfo.start_date = searchInfo.from_date.toISOString();
    this.searchInfo.end_date = searchInfo.to_date.toISOString();
    this.searchFormParamsSubject$.next(this.searchInfo);
  }

  refresh(): void {
    this.searchFormParamsSubject$.next(this.searchInfo)
  }
}
