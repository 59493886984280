import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatFormFieldControl, DateAdapter, MatDatepicker, NativeDateAdapter } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { SearchInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip, flatMap, tap, take } from 'rxjs/operators';
import { Observable, Subscription, pipe } from 'rxjs';
import { SearchFormService } from '../../../service/search-form/search-form.service';
import { TitleService } from '../../../service/title.service';

export class MyDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    const dateNames: string[] = [];
    for (let i = 0; i < 31; i++) {
      dateNames[i] = String(i + 1);
    }
    return dateNames;
  }
}

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-search-form',
  templateUrl: './search-form.component.html',
  styleUrls: ['./search-form.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter}
  ]
})

export class SearchFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  formGroup: FormGroup;
  searchInfo: SearchInfo;
  date: Date;
  fromDate: Date;
  toDate: Date;
  minDate: Date;
  fromMaxDate: Date;
  maxDate: Date;
  csvQuery: string;

  constructor(
    private searchFormService: SearchFormService,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private titleService: TitleService
  ) {}


  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title  => title === '検品結果'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.searchFormService.setSearchFormParams(this.searchInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('検品結果検索設定：成功');
          }
        )
    );
    
    Object.keys(this.searchInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            if (key === 'fromDate') {
              this.fromDate = value;
                if (this.formGroup.get('fromDate').value > this.formGroup.get('toDate').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('fromDate').setValue(this.toDate);
                  this.formGroup.get('toDate').setValue(this.fromDate);
                  this.searchInfo.fromDate = this.toDate;
                  this.searchInfo.toDate = this.fromDate;
                } else {
                  this.fromMaxDate = this.formGroup.get('toDate').value;
                  this.searchInfo.fromDate = this.formGroup.get('fromDate').value;
                  this.searchInfo.toDate = this.formGroup.get('toDate').value;
                }
            }
            if (key === 'toDate') {
              this.toDate = value;
                if (this.formGroup.get('fromDate').value > this.formGroup.get('toDate').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('fromDate').setValue(this.toDate);
                  this.formGroup.get('toDate').setValue(this.fromDate);
                  this.searchInfo.fromDate = this.toDate;
                  this.searchInfo.toDate = this.fromDate;
                } else {
                  this.fromMaxDate = this.formGroup.get('toDate').value;
                  this.searchInfo.fromDate = this.formGroup.get('fromDate').value;
                  this.searchInfo.toDate = this.formGroup.get('toDate').value;
                }
            }
            this.searchInfo[key] = value;
          })
      );
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.searchInfo = {
      batch_no: '',
      direction_no: '',
      item_code: '',
      jancode: '',
      user_id: '',
      trace_no: '',
      fromDate: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()),
      toDate: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate())
    };
    this.search();
  }

  search() {
    this.searchFormService.setSearchFormParams(this.searchInfo);
  }

  download() {
    const a = document.createElement('a');
    a.download = '検品結果.csv';
    // tslint:disable-next-line:max-line-length
    a.href = `/api/v1/check/row/result/csv?batch_no=${this.searchInfo.batch_no}&direction_no=${this.searchInfo.direction_no}&item_cd=${this.searchInfo.item_code}&user_id=${this.searchInfo.user_id}&start_date=${this.searchInfo.fromDate.toISOString()}&end_date=${this.searchInfo.toDate.toISOString()}`;
    a.click();
    a.remove();
  }

  private fieldInit() {
    this.subscriptions = [];
    this.date = new Date();
    this.fromDate = new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.toDate = new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 90);
    this.maxDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    this.fromMaxDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    this.dateAdapter.setLocale('ja');
    this.searchInfo = {
      batch_no: '',
      direction_no: '',
      item_code: '',
      jancode: '',
      user_id: '',
      trace_no: '',
      fromDate: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()),
      toDate: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate())
    };
    this.formGroup = this.formBuilder.group({
      batch_no: this.formBuilder.control(''),
      direction_no: this.formBuilder.control(''),
      item_code: this.formBuilder.control(''),
      jancode: this.formBuilder.control(''),
      user_id: this.formBuilder.control(''),
      trace_no: this.formBuilder.control(''),
      fromDate: this.formBuilder.control({
        value: this.fromDate,
        disabled: true
      }),
      toDate: this.formBuilder.control({
        value: this.toDate,
        disabled: true
      })
    });
  }
}

