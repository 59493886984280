import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CollationSearchInfo, CollationQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class CollationSearchFormService {
  searchFormParamsSubject$: BehaviorSubject<CollationQueryInfo>;
  csvEventSubject$: BehaviorSubject<CollationQueryInfo>;
  searchFormParams$: Observable<CollationQueryInfo>;
  csvEvent$: Observable<CollationQueryInfo>;
  searchInfo: CollationQueryInfo;
  date: Date;

  constructor() {
    this.date = new Date();
    this.searchInfo = {
      store_name: '',
      item_code: '',
      user_name: '',
      start_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString(),
      end_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString()
    };
    this.searchFormParamsSubject$ = new BehaviorSubject(this.searchInfo);
    this.csvEventSubject$ = new BehaviorSubject(this.searchInfo);
    this.searchFormParams$ = this.searchFormParamsSubject$.asObservable();
    this.csvEvent$ = this.csvEventSubject$.asObservable();
  }

  setSearchFormParams(searchInfo: CollationSearchInfo): void {
    this.searchInfo.store_name = searchInfo.store_name
    this.searchInfo.item_code = searchInfo.item_code;
    this.searchInfo.user_name = searchInfo.user_name;
    this.searchInfo.start_date = searchInfo.from_date.toISOString();
    this.searchInfo.end_date = searchInfo.to_date.toISOString();
    this.searchFormParamsSubject$.next(this.searchInfo);
  }

  repeat(): void {
    this.searchFormParamsSubject$.next(this.searchInfo)
  }

  startDownload(): void {
    this.csvEventSubject$.next(this.searchInfo);
  }
}
