import { NgModule } from '@angular/core';
import { TraceResultComponent } from './trace-result.component';
import { SharedModule } from '../shared/shared.module';
import { TraceResultTableComponent } from '../../component/table/trace-result-table/trace-result-table.component';
import { TraceSearchFormComponent } from '../../component/search-form/trace-search-form/trace-search-form.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    TraceResultComponent,
    TraceSearchFormComponent,
    TraceResultTableComponent
  ],
  providers: [],
  declarations: [
    TraceResultTableComponent,
    TraceResultComponent,
    TraceSearchFormComponent
  ]
})
export class TraceResultModule { }
