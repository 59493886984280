import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { SearchInfo } from '../../../../interface/search.interface';
import { TraceResult } from '../../../../interface/result.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { SearchFormService } from '../../../service/search-form/search-form.service';
import { subscribeOn, debounceTime, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-trace-result-table',
  templateUrl: './trace-result-table.component.html',
  styleUrls: ['./trace-result-table.component.css']
})
export class TraceResultTableComponent implements OnInit {
  subscriptions: Subscription[];
  columns: string[];
  searchInfo: SearchInfo;
  casheData: TraceResult[];
  dataSource: MatTableDataSource<TraceResult>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private searchFormSevice: SearchFormService
    ) { }

    ngOnInit() {
      this.subscriptions = [];
      this.setColumns();
      this.subscriptions.push(
        this.searchFormSevice.searchFormParams$
          .pipe(
            flatMap(query => this.requestService.get('/check/trace/row/result', query))
          )
          .subscribe(
            res => {
            this.casheData = Array.isArray(res) ? res : [];
            this.dataSource = new MatTableDataSource(this.casheData);
            this.dataSource.sort = this.sort;
            console.log(res)
            },
            error => {
              console.log(`検品結果テーブル生成 : ${error}`);
            },
            () => {
              console.log('検品結果テーブル生成 : 成功');
            }
          )
        );
      this.titleService.setTitle('追跡番号詳細');
    }

    ngOnDestroy() {
      this.subscriptions.forEach((subscription) => {
        subscription.unsubscribe();
      });
    }

    setColumns(): void {
      this.columns = [
        'batch_no',
        'direction_no',
        'item_cd',
        'jancode',
        'item_name',
        'trace_no',
        'destination',
        'customer_shop_cd',
        'created_at',
        'started_at',
        'finished_at'
      ];
    }
  }
