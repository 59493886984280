import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatFormFieldControl, DateAdapter, MatDatepicker, NativeDateAdapter } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { RecycleQueryInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip, flatMap, tap, take } from 'rxjs/operators';
import { Observable, Subscription, pipe } from 'rxjs';
import { RecycleSearchFormService } from '../../../service/search-form/recycle-search-form.service';
import { TitleService } from '../../../service/title.service';
import { HttpHeaders } from '@angular/common/http';

export class MyDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    const dateNames: string[] = [];
    for (let i = 0; i < 31; i++) {
      dateNames[i] = String(i + 1);
    }
    return dateNames;
  }
}

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-recycle-search-form',
  templateUrl: './recycle-search-form.component.html',
  styleUrls: ['./recycle-search-form.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter}
  ]
})

export class RecycleSearchFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  formGroup: FormGroup;
  searchInfo: RecycleQueryInfo;
  now: Date = new Date();
  start_date: Date;
  end_date: Date;
  minDate: Date;
  fromMaxDate: Date;
  maxDate: Date;
  csvQuery: string;

  constructor(
    private recycleSearchFormService: RecycleSearchFormService,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private titleService: TitleService
  ) {}

  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title  => title === 'リサイクル券記録履歴'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.recycleSearchFormService.setRecycleFormParams(this.searchInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('検品結果検索設定：成功');
          }
        )
    );
    
    Object.keys(this.searchInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            if (key === 'start_date') {
              this.start_date = value;
                if (this.formGroup.get('start_date').value > this.formGroup.get('end_date').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('start_date').setValue(this.end_date);
                  this.formGroup.get('end_date').setValue(this.start_date);
                  this.searchInfo.start_date = this.end_date.toISOString();
                  this.searchInfo.end_date = this.start_date.toISOString();
                } else {
                  this.fromMaxDate = this.formGroup.get('end_date').value;
                  this.searchInfo.start_date = this.formGroup.get('start_date').value.toISOString();
                  this.searchInfo.end_date = this.formGroup.get('end_date').value.toISOString();
                }
            }
            if (key === 'end_date') {
              this.end_date = value;
                if (this.formGroup.get('start_date').value > this.formGroup.get('end_date').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('start_date').setValue(this.end_date);
                  this.formGroup.get('end_date').setValue(this.start_date);
                  this.searchInfo.start_date = this.end_date.toISOString();
                  this.searchInfo.end_date = this.start_date.toISOString();
                } else {
                  this.fromMaxDate = this.formGroup.get('end_date').value;
                  this.searchInfo.start_date = this.formGroup.get('start_date').value.toISOString();
                  this.searchInfo.end_date = this.formGroup.get('end_date').value.toISOString();
                }
            }
            this.searchInfo[key] = value;
          })
      );
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.searchInfo = {
      // batch_no: '',
      direction_no: '',
      // item_code: '',
      recycle_no: '',
      user_id: '',
      start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString()
    };
    this.search();
  }

  search() {
    this.recycleSearchFormService.setRecycleFormParams(this.searchInfo);
  }

  download() {
    const a = document.createElement('a');
    a.download = 'リサイクル券登録履歴_'+ this.now.getFullYear() + ('0' + String(this.now.getMonth() + 1)).slice(-2) + ('0' + String(this.now.getDate())).slice(-2) + '.csv';
    // tslint:disable-next-line:max-line-length
    a.href = `/api/v1/recycle/row/result/csv?direction_no=${this.searchInfo.direction_no}&recycle_numbet=${this.searchInfo.recycle_no}&user_id=${this.searchInfo.user_id}&start_date=${this.searchInfo.start_date}&end_date=${this.searchInfo.end_date}`;
    a.click();
    a.remove();
  }

  private fieldInit() {
    this.subscriptions = [];
    this.now = new Date();
    this.start_date = new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate());
    this.end_date = new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate());
    this.minDate = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - 365);
    this.maxDate = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    this.fromMaxDate = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    this.dateAdapter.setLocale('ja');
    this.searchInfo = {
      // batch_no: '',
      direction_no: '',
      // item_code: '',
      recycle_no: '',
      user_id: '',
      start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString()
    };
    this.formGroup = this.formBuilder.group({
      // batch_no: this.formBuilder.control(''),
      direction_no: this.formBuilder.control(''),
      // item_code: this.formBuilder.control(''),
      recycle_no: this.formBuilder.control(''),
      user_id: this.formBuilder.control(''),
      start_date: this.formBuilder.control({
        value: this.start_date,
        disabled: true
      }),
      end_date: this.formBuilder.control({
        value: this.end_date,
        disabled: true
      })
    });
  }
}

