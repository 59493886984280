import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { CollationItem } from '../../interface/interface';

@Injectable({
  providedIn: 'root'
})
export class CollationDialogService {
  collationDialogInitSubject$: BehaviorSubject<CollationItem>;
  collationDialogInit$: Observable<CollationItem>;
  now: Date = new Date();

  constructor() {
    this.collationDialogInitSubject$ = new BehaviorSubject(
      {
        id: '',
        store_name: '',
        item_code: '',
        item_count: ''
      }
    );
    this.collationDialogInit$ = this.collationDialogInitSubject$.asObservable();
  }
/**
 * @param user_id ログイン中の担当者ID
 */
  setCollationDialogInit(info: CollationItem): void {
    this.collationDialogInitSubject$.next(info);
  }
}
