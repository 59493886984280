import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '../../service/request.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LoginUser } from '../../../interface/interface';
import { UserInfoService } from '../../service/user-info.service';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  formGroup: FormGroup;
  userId: string;
  password: string;
  companyCode: string;
  loginUser: LoginUser;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private formBuilder: FormBuilder,
    private userInfoService: UserInfoService,
    private cookieService: CookieService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.loginUser = {
      user_id: '',
      name: '',
      company_code: '',
      company_name: '',
      auth_id: '',
      scope: '',
      cloud_batch_progress: false,
      cloud_direction_upload: false, 
      cloud_items_upload: false, 
      cloud_check_result: false,
      cloud_serial_detail: false, 
      cloud_recycle_result: false,
      cloud_recycle_upload: false,
      cloud_purchase_result: false,
      cloud_purchase_regist: false,
      cloud_user_master: false,
      cloud_collation: false
    };
    this.formGroup = this.formBuilder.group({
      userId: this.formBuilder.control(''),
      password: this.formBuilder.control(''),
      companyCode: this.formBuilder.control('')
    });
    this.subscriptions.push(
      this.formGroup.get('userId').valueChanges
        .subscribe((value) => {
          this.userId = value;
        })
    );
    this.subscriptions.push(
      this.formGroup.get('password').valueChanges
        .subscribe((value) => {
          this.password = value;
        })
    );
    this.subscriptions.push(
      this.formGroup.get('companyCode').valueChanges
        .subscribe((value) => {
          this.companyCode = value;
        })
    );
    if (document.cookie) { this.router.navigateByUrl('index'); }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  login() {
    const body = {
      userId: this.userId,
      pass: this.password,
      companyCode: this.companyCode
    };
    this.requestService.post('/login', body, new HttpHeaders())
    // const headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    // this.subscriptions.push(
    //   this.http.post<LoginUser>(
    //     '/api/v1/login',
    //     body,
    //     {
    //       headers: headers,
    //       // observe: 'response',
    //       withCredentials: true
    //      }
    //   )
      .subscribe(() => {
        if (document.cookie) {
          let info = JSON.parse(this.cookieService.get('userInfo'))
          this.userInfoService.setUserInfo(info);
          console.log('ログイン：成功');
          if (info.cloud_batch_progress == true) {
            this.router.navigateByUrl('/index');
          } else if (info.cloud_direction_upload == true) {
            this.router.navigateByUrl('/index/upload/direction');
          } else if (info.cloud_items_upload == true) {
            this.router.navigateByUrl('/index/upload/item');
          } else if (info.cloud_check_result == true) {
            this.router.navigateByUrl('/index/check/item');
          } else if (info.cloud_serial_detail == true) {
            this.router.navigateByUrl('/index/check/trace');
          } else if (info.cloud_recycle_result == true) {
            this.router.navigateByUrl('/index/check/recycle');
          } else if (info.cloud_recycle_upload == true) {
            this.router.navigateByUrl('/index/upload/recycle');
          } else if (info.cloud_purchase_result == true) {
            this.router.navigateByUrl('/index/check/purchase');
          } else if (info.cloud_purchase_regist == true) {
            this.router.navigateByUrl('/index/check/purchase');
          } else {
            this.router.navigateByUrl('/index/config');
          }
        }
      });
  }
}
