import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { SpinnerComponent } from '../component/spinner/spinner.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  dialogRef: MatDialogRef<SpinnerComponent>;
  constructor(
    public matDialog: MatDialog
  ) { }

  createSpinner(): void {
    setTimeout(() => {
      this.dialogRef = this.matDialog.open(SpinnerComponent, {
        'disableClose': true
      });
    });
  }
  deleteSpinner(): void {
    setTimeout(() => {
      this.dialogRef.close();
    });
  }
}
