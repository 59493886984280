import { NgModule } from '@angular/core';
import { ItemListComponent } from './item-list.component';
import { SharedModule } from '../shared/shared.module';
import { ItemTableComponent } from '../../component/table/item-table/item-table.component';
import { ItemSearchFormComponent } from '../../component/search-form/item-search-form/item-search-form.component';
import { ContentRoutingModule } from '../../router/content-routing/content-routing.module';

@NgModule({
  imports: [
    SharedModule,
    ContentRoutingModule
  ],
  exports: [
    SharedModule,
    ItemTableComponent,
    ItemSearchFormComponent,
    ItemListComponent,
    ContentRoutingModule,
  ],
  declarations: [
    ItemTableComponent,
    ItemSearchFormComponent,
    ItemListComponent,
  ]
})
export class ItemListModule { }
