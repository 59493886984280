import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  titleSubject$: BehaviorSubject<string> = new BehaviorSubject('');
  title$: Observable<string>;
  title: string;

  constructor() {
    this.title = '';
    this.title$ = this.titleSubject$.asObservable();
  }

  setTitle(title) {
    this.title = title;
    this.titleSubject$.next(title);
  }
}
