import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { TitleService } from '../../service/title.service';
import { RequestService } from '../../service/request.service';
import { MyDate } from '../../../interface/interface';
import { filter, subscribeOn, debounceTime, debounce } from 'rxjs/operators';
import { Observable, fromEvent, Subscription, pipe } from 'rxjs';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.css']
})
export class ContentHeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  title: string;
  date: Date;

  constructor(
    private titleService: TitleService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.subscriptions.push(
      this.titleService.title$
      .pipe(
        debounceTime(100)
      )
      .subscribe(
        (title) => {
          this.title = title;
        },
        error => {
          console.log(error);
        },
        () => {
          console.log('コンテンツタイトル生成：成功');
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }
}
