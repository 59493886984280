import { Component, OnInit,OnDestroy, ViewChild } from '@angular/core';
import { MatFormFieldControl } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MessageQueryInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip } from 'rxjs/operators';
import { Observable, Subscription, pipe } from 'rxjs';
import { MessageFormService } from '../../../service/search-form/message-form.service';
import { TitleService } from '../../../service/title.service';

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-message-search-form',
  templateUrl: './message-search-form.component.html',
  styleUrls: ['./message-search-form.component.css']
})
export class MessageSearchFormComponent implements OnInit, OnDestroy{
  subscriptions: Subscription[];
  formGroup: FormGroup;
  messageInfo: MessageQueryInfo;

  csvQuery: string;

  constructor(
    private messageFormService: MessageFormService,
    private formBuilder: FormBuilder,
    private titleService: TitleService
    ) { }

  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title  => title === '照合用メッセージマスタ一覧'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.messageFormService.setMessageFormParams(this.messageInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('照合用メッセージ一覧初期化：成功');
          }
        )
    );

    Object.keys(this.messageInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            this.messageInfo[key] = value;
          })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.messageInfo = {
      jancode: '',
      alert_message: ''
    };
    this.search();
  }

  search() {
    this.messageFormService.setMessageFormParams(this.messageInfo);
  }

  private fieldInit() {
    this.subscriptions = [];
    this.messageInfo = {
      jancode: '',
      alert_message: ''
    };

    this.formGroup = this.formBuilder.group({
      jancode: this.formBuilder.control(''),
      alert_message: this.formBuilder.control('')
    });
  }
}
