import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { PurchaseItem } from '../../../interface/interface';

@Injectable({
  providedIn: 'root'
})
export class PurchaseDialogService {
  purchaseDialogInitSubject$: BehaviorSubject<PurchaseItem>;
  purchaseDialogInit$: Observable<PurchaseItem>;
  now: Date = new Date();

  constructor() {
    this.purchaseDialogInitSubject$ = new BehaviorSubject(
      {
        id: '',
        slip_number: '',
        supplier_name: '',
        arrival_date: this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate(),
        item_code: '',
        serial_number: '',
        remarks: ''
      }
    );
    this.purchaseDialogInit$ = this.purchaseDialogInitSubject$.asObservable();
  }
/**
 * @param user_id ログイン中の担当者ID
 */
  setPurchaseDialogInit(info: PurchaseItem): void {
    this.purchaseDialogInitSubject$.next(info);
  }
}
