import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'auth'
})
export class AuthPipe implements PipeTransform {

  transform(value: string): string {
    let auth_name: string;
    switch (value) {
      case 'Admin':
        auth_name = '管理者';
        break;
      default:
        auth_name = '一般ユーザー';
        break;
    }
    return auth_name;
  }

}
