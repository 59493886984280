import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { RecycleQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class RecycleSearchFormService {
  recycleSearchFormParamsSubject$: BehaviorSubject<RecycleQueryInfo>;
  recycleSearchFormParams$: Observable<RecycleQueryInfo>;
  date: Date = new Date();

  constructor() {
    this.recycleSearchFormParamsSubject$ = new BehaviorSubject({
      // batch_no: '',
      direction_no: '',
      // item_code: '',
      recycle_no: '',
      user_id: '',
      start_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString(),
      end_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString()
    });
    this.recycleSearchFormParams$ = this.recycleSearchFormParamsSubject$.asObservable();
  }

  setRecycleFormParams(searchInfo: RecycleQueryInfo): void {
    this.recycleSearchFormParamsSubject$.next(searchInfo);
  }
}
