import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-result',
  templateUrl: './recycle-result.component.html',
  styleUrls: ['./recycle-result.component.css']
})
export class RecycleResultComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
