import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatFormFieldControl, DateAdapter, MatDatepicker, NativeDateAdapter } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { WorkProgressSearchInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip, flatMap, tap, take } from 'rxjs/operators';
import { Observable, Subscription, pipe } from 'rxjs';
import { WorkProgressSearchFormService } from '../../../service/search-form/work-progress-search-form.service';
import { TitleService } from '../../../service/title.service';

export class MyDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    const dateNames: string[] = [];
    for (let i = 0; i < 31; i++) {
      dateNames[i] = String(i + 1);
    }
    return dateNames;
  }
}

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-work-progress-search-form',
  templateUrl: './work-progress-search-form.component.html',
  styleUrls: ['./work-progress-search-form.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MyDateAdapter}
  ]
})

export class WorkProgressSearchFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  formGroup: FormGroup;
  searchInfo: WorkProgressSearchInfo;
  date: Date;
  fromDate: Date;
  toDate: Date;
  minDate: Date;
  fromMaxDate: Date;
  maxDate: Date;

  constructor(
    private searchFormService: WorkProgressSearchFormService,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private titleService: TitleService
  ) {}


  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title  => title === 'バッチ別作業進捗'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.searchFormService.setSearchFormParams(this.searchInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('バッチ別作業進捗検索設定：成功');
          }
        )
    );
    
    Object.keys(this.searchInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            if (key === 'from_date') {
              this.fromDate = value;
                if (this.formGroup.get('from_date').value > this.formGroup.get('to_date').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('from_date').setValue(this.toDate);
                  this.formGroup.get('to_date').setValue(this.fromDate);
                  this.searchInfo.from_date = this.toDate;
                  this.searchInfo.to_date = this.fromDate;
                } else {
                  this.fromMaxDate = this.formGroup.get('to_date').value;
                  this.searchInfo.from_date = this.formGroup.get('from_date').value;
                  this.searchInfo.to_date = this.formGroup.get('to_date').value;
                }
            }
            if (key === 'to_date') {
              this.toDate = value;
                if (this.formGroup.get('from_date').value > this.formGroup.get('to_date').value) {
                  this.fromMaxDate = value;
                  this.formGroup.get('from_date').setValue(this.toDate);
                  this.formGroup.get('to_date').setValue(this.fromDate);
                  this.searchInfo.from_date = this.toDate;
                  this.searchInfo.to_date = this.fromDate;
                } else {
                  this.fromMaxDate = this.formGroup.get('to_date').value;
                  this.searchInfo.from_date = this.formGroup.get('from_date').value;
                  this.searchInfo.to_date = this.formGroup.get('to_date').value;
                }
            }
            this.searchInfo[key] = value;
          })
      );
    });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.searchInfo = {
      shop_code: '',
      from_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()),
      to_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate())
    };
    this.search();
  }

  search() {
    this.searchFormService.setSearchFormParams(this.searchInfo);
  }

  private fieldInit() {
    this.subscriptions = [];
    this.date = new Date();
    this.fromDate = new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.toDate = new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate());
    this.minDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate() - 90);
    this.maxDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    this.fromMaxDate = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate());
    this.dateAdapter.setLocale('ja');
    this.searchInfo = {
      shop_code: '',
      from_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()),
      to_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate())
    };
    this.formGroup = this.formBuilder.group({
      shop_code: this.formBuilder.control(''),
      from_date: this.formBuilder.control({
        value: this.fromDate,
        disabled: true
      }),
      to_date: this.formBuilder.control({
        value: this.toDate,
        disabled: true
      })
    });
  }
}

