import { NgModule } from '@angular/core';
import { RecycleResultComponent } from './recycle-result.component';
import { SharedModule } from '../shared/shared.module';
import { RecycleResultTableComponent } from '../../component/table/recycle-result-table/recycle-result-table.component';
import { RecycleSearchFormComponent } from '../../component/search-form/recycle-search-form/recycle-search-form.component';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    RecycleResultComponent,
    RecycleSearchFormComponent,
    RecycleResultTableComponent
  ],
  providers: [],
  declarations: [
    RecycleResultTableComponent,
    RecycleResultComponent,
    RecycleSearchFormComponent
  ]
})
export class RecycleResultModule { }
