import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../service/title.service';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestService } from '../../../service/request.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginUser } from '../../../../interface/interface';
import { UserInfoService } from '../../../service/user-info.service';

@Component({
  selector: 'app-recycle-upload',
  templateUrl: './recycle-upload.component.html',
  styleUrls: ['./recycle-upload.component.css']
})
export class RecycleUploadComponent implements OnInit {
  subscriptions: Subscription[];
  file: any;
  loginUserInfo: LoginUser;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.subscriptions.push(
      this.userInfoService.userInfo$
        .subscribe((loginUserInfo) => {
          this.loginUserInfo = loginUserInfo;
        })
    );
    this.titleService.setTitle('リサイクル券指示情報CSVファイル取込');
  }

  upload() {
    if (!this.file) {return; }
    const body = this.file;
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Barnet-File-Name': btoa(unescape(encodeURIComponent(this.file.name))),
      'Barnet-User': this.loginUserInfo.user_id
    });
    this.subscriptions.push(
      this.requestService.post('/csv/recycle', body, headers)
      .subscribe((response) => {
        if ( response.length !== 0) {
          const errDirectionNo = response.duplicatedDiretionNo.join(`\n`);
          alert(`アップロード処理完了\n成功件数：${response.successCount}\n失敗件数：${response.failedCount}\n重複指示No:\n${errDirectionNo}`);
        }
      })
    );
  }
  getFile(event) {
    this.file = event.target.files[0];
    const csvData = this.file;
  }
}
