import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from '../../service/request.service';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { LoginUser } from '../../../interface/interface';
import { UserInfoService } from '../../service/user-info.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  company: string;

  constructor(
    private router: Router,
    private requestService: RequestService,
    private cookieService: CookieService,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    this.subscriptions = [];

    if (document.cookie) {
      this.userInfoService.setUserInfo(JSON.parse(this.cookieService.get('userInfo')));
    }

    this.subscriptions.push(
      this.userInfoService.userInfo$
        .subscribe((loginUser: LoginUser) => {
          this.company = loginUser.company_name;
        })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  logout() {
    this.subscriptions.push(
      this.requestService.get('/logout', {})
        .subscribe(
          (res) => {
            this.cookieService.deleteAll();
            this.userInfoService.setUserInfo({
              user_id: '',
              name: '',
              company_code: '',
              company_name: '',
              auth_id: '',
              scope: '',
              cloud_batch_progress: false,
              cloud_direction_upload: false, 
              cloud_items_upload: false, 
              cloud_check_result: false,
              cloud_serial_detail: false, 
              cloud_recycle_result: false, 
              cloud_recycle_upload: false, 
              cloud_purchase_result: false, 
              cloud_purchase_regist: false, 
              cloud_user_master: false,
              cloud_collation:false
            });
            this.router.navigateByUrl('');
        },
        error => {
          console.log(error);
        },
        () => {
          console.log('ログアウト処理：成功');
        }
      )
    );
  }
}
