import { Injectable, Inject } from '@angular/core';
import {
  HttpClientModule,
  HttpClient,
  HttpHeaders,
  HttpParams,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpEventType,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { HttpParamsOptions } from '@angular/common/http/src/params';
import { Observable, of, pipe, Subject, throwError } from 'rxjs';
import { catchError, finalize, tap, timeout, filter } from 'rxjs/operators';
import { SpinnerService } from './spinner.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { UserInfoService } from './user-info.service';
// import { APP_BASE_HREF } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class RequestService {
  baseUrl: string;
  headers: HttpHeaders;
  request$: Observable<any>;
  requestSubject$: Subject<any>;

  constructor(
    // @Inject(APP_BASE_HREF) private _baseHref: string,
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.baseUrl = '/api/v1';
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json'
    });
  }

  get(api: string, params: Object, headers: HttpHeaders = this.headers): Observable<any> {
    const queryParams = new HttpParams(<HttpParamsOptions>{fromObject: params});
    return this.http.get<any>(
      this.baseUrl + api,
      Object.keys(params).length === 0 ? {
        headers: headers,
        withCredentials: true
      } : {
        headers: headers,
        params: queryParams,
        withCredentials: true
      }
    )
    .pipe(
      catchError(this.handleError('get', []))
    );
  }

  post(api: string, data: any, headers: HttpHeaders = this.headers): Observable<any> {
    this.headers = Object.keys(headers).length > 0 ? headers : this.headers;
    return this.http.post<any[]>(
      this.baseUrl + api,
      data,
      {
        headers: this.headers,
        withCredentials: true
      }
    )
    .pipe(
      catchError(this.handleError('post', []))
    );
  }

  delete(api: string, params: Object, headers: HttpHeaders = this.headers): Observable<any> {
    const queryParams = new HttpParams(<HttpParamsOptions>{fromObject: params});
    this.headers = Object.keys(headers).length > 0 ? headers : this.headers;
    return this.http.delete<any[]>(
      this.baseUrl + api,
      Object.keys(params).length === 0 ? {
        headers: headers,
        withCredentials: true
      } : {
        headers: headers,
        params: queryParams,
        withCredentials: true
      }
    )
    .pipe(
      catchError(this.handleError('delete', []))
    );
  }

  patch(api: string, data: any, headers: HttpHeaders): Observable<any> {
    this.headers = Object.keys(headers).length > 0 ? headers : this.headers;
    return this.http.patch<any[]>(
      this.baseUrl + api,
      data,
      {
        headers: this.headers,
        withCredentials: true
      }
    )
    .pipe(
      catchError(this.handleError('patch', []))
    );
  }

  // エラータイプごとの処理
  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      if (error.error.type === 'login_failed') {
        this.cookieService.deleteAll();
      }
      let msg = error.error.message;
      if (error.error.status === 200) {
        msg = '';
      }
      switch (error.error.type) {
      case 'no_file_name':
      case 'no_user_id':
      case 'invalid_csv_length_22':
      case 'invalid_csv_shop_code':
      case 'invalid_csv_unknown':
      case 'invalid_csv_db':
        msg = `アップロード失敗:${error.error.message}`;
      }
      if (msg) { alert(msg); }

      // フリーズを避ける為に空のObservableを返す
      return of(result as T);
    };
  }
}

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  baseUrl: string;

  constructor(
    private spinnerService: SpinnerService,
    private router: Router,
    private cookieService: CookieService,
    private userInfoService: UserInfoService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.spinnerService.createSpinner();
    // const req: HttpRequest<any> = request;
    // if (req.url === '/api/v1/csv') {
    //   req = request.clone(
    //     {
    //       url: req.url,
    //       withCredentials: true,
    //       responseType: 'text',
    //       setHeaders: {
    //         'Content-Type': 'application/json'
    //       }
    //     }
    //   );
    // }
    // console.log(req);
    return next.handle(request)
      .pipe(
        filter(event => event instanceof HttpResponse),
        tap(() => {
          this.spinnerService.deleteSpinner();
          // console.log('httpResponse', httpResponse, new Date());
        }),
      //   (err: any) => {
      //     if (err instanceof HttpErrorResponse) {
      //       const msg = err.message;
      //       switch (err.status) {
      //         case 403:
      //           alert('セッションがありません、再度ログインしてください');
      //           this.router.navigateByUrl('');
      //           return;
      //         case 404:
      //         case 500:
      //           alert(msg);
      //           return;
      //         default:
      //           this.errorService.setHttpError([String(err.status), err.message]);
      //           return throwError(err);
      //       }
      //     }
      //   }
      // ),
      // 通信周りの予想されるエラーを処理
        catchError((err) => {
          if (err instanceof HttpErrorResponse) {
            switch (err.status) {
              // case 200:
              //   console.log(err);
              //   return throwError('');
              case 401:
                alert('セッションがありません、再度ログインしてください');
                this.cookieService.deleteAll();
                this.userInfoService.setUserInfo({
                  user_id: '',
                  name: '',
                  company_code: '',
                  company_name: '',
                  auth_id: '',
                  scope: '',
                  cloud_batch_progress: false,
                  cloud_direction_upload: false,
                  cloud_items_upload: false,
                  cloud_check_result: false,
                  cloud_serial_detail: false,
                  cloud_recycle_result: false,
                  cloud_recycle_upload: false,
                  cloud_purchase_result: false,
                  cloud_purchase_regist: false,
                  cloud_user_master: false,
                  cloud_collation: false
                });
                this.router.navigateByUrl('');
                return of({} as HttpEvent<any>);
              case 404:
                alert('通信しようとしているAPIが見つかりません');
                return of({} as HttpEvent<any>);
              default:
                return throwError(err);
            }
          }
        }),
        finalize(() => {
          this.spinnerService.deleteSpinner();
        })
      );
  }
}
