import { Component, OnInit, OnDestroy } from '@angular/core';
import { TitleService } from '../../service/title.service';
import { UserInfoService } from '../../service/user-info.service';
import { Subscription } from 'rxjs';
import { LoginUser } from '../../../interface/interface';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  title: string;
  user_name: string;
  auth: string;
  company: string;
  cloud_batch_progress: boolean;
  cloud_direction_upload: boolean;
  cloud_items_upload: boolean;
  cloud_check_result: boolean;
  cloud_serial_detail: boolean;
  cloud_user_master: boolean;
  cloud_recycle_result: boolean;
  cloud_recycle_upload: boolean; 
  cloud_purchase_result: boolean;
  cloud_collation: boolean;

  constructor(
    private titleService: TitleService,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    
    this.subscriptions.push(
      this.userInfoService.userInfo$
        .subscribe((loginUser: LoginUser) => {
          this.user_name = loginUser.name;
          this.auth = loginUser.auth_id === 'Admin' ? '管理者' : '一般ユーザー';
          this.company = loginUser.company_name;
          this.cloud_batch_progress = loginUser.cloud_batch_progress;
          this.cloud_direction_upload = loginUser.cloud_direction_upload;
          this.cloud_items_upload = loginUser.cloud_items_upload;
          this.cloud_check_result = loginUser.cloud_check_result;
          this.cloud_serial_detail = loginUser.cloud_serial_detail;
          this.cloud_user_master = loginUser.cloud_user_master;
          this.cloud_recycle_result = loginUser.cloud_recycle_result;
          this.cloud_recycle_upload = loginUser.cloud_recycle_upload;
          this.cloud_purchase_result = loginUser.cloud_purchase_result;
          this.cloud_collation = loginUser.cloud_collation;
        })
    );
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  throwTitle(title: string): void {
    this.titleService.setTitle(title);
  }
}
