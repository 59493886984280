import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, Sort } from '@angular/material';

import { Subscription, Observable, Subject } from 'rxjs';
import { User, UserData, LoginUser } from '../../../interface/interface';

import { TitleService } from '../../service/title.service';
import { FormService } from '../../service/form.service';
import { UserFormDialogComponent } from '../dialog/user-form-dialog/user-form-dialog.component';

import { RequestService } from '../../service/request.service';
import { filter, debounceTime, flatMap, map, tap, refCount, publish } from 'rxjs/operators';
import { UserInfoService } from '../../service/user-info.service';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.css']
})
export class UserTableComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  columns: string[];
  userData: UserData;
  loginUserName: string;
  dataSource: MatTableDataSource<UserData>;
  casheData: UserData[];
  formFlg: boolean;
  user: User;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public matDialog: MatDialog,
    private formService: FormService,
    private requestService: RequestService,
    private titleService: TitleService,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.loginUserName = '';
    this.setColumns();
    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title => title === '担当者登録'),
          flatMap(title => this.requestService.get('/user', {}))
        )
        .subscribe((res: UserData[]) => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
        })
      );
    this.subscriptions.push(
      this.userInfoService.userInfo$
        .subscribe((userInfo: LoginUser) => {
          this.loginUserName = userInfo.name;
        })
    );
    this.titleService.setTitle('担当者登録');
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setColumns(): void {
    this.columns = [
      'user_id',
      'name',
      'auth_id',
      'company',
      'updated_at',
      'updated_by'
    ];
  }
  createForm(row, formType: string): void {
    this.formFlg = formType === 'update' ? true : false;
    this.formService.setUserFormInit(row, this.formFlg);
    const dialog = this.matDialog.open(UserFormDialogComponent,
      {
        'height' : '75vh',
        'width' : '50vw',
        'disableClose' : false
      }
    );
    const user$ = dialog.afterClosed()
      .pipe(
        tap(result => console.log(result)),
        publish(),
        refCount()
      );
    this.subscriptions.push(
      user$
        .pipe(
          filter(result => result && !this.formFlg),
          tap(),
          flatMap(result => this.requestService.post('/user', result, new HttpHeaders({'Content-Type': 'application/json'})))
        )
        .subscribe((res) => {
          this.titleService.setTitle('担当者登録');
          alert('登録が完了しました');
        })
    );
    this.subscriptions.push(
      user$
        .pipe(
          filter(result => result && this.formFlg),
          flatMap(result => this.requestService.patch('/user', result, new HttpHeaders({'Content-Type': 'application/json'})))
        )
        .subscribe((res) => {
          this.titleService.setTitle('担当者登録');
          alert('更新が完了しました');
        })
    );
  }
  sortData(sort: Sort) {
    const data = this.casheData.slice();
    if (!sort.active || sort.direction === '') {
      this.casheData = data;
      return;
    }

    this.casheData = data.sort((a, b) => {
      const isDesc = sort.direction === 'desc';
      switch (sort.active) {
        case 'user_id': return this.compare(a.user_id, b.user_id, isDesc);
        case 'name': return this.compare(a.name, b.name, isDesc);
        case 'auth_id': return this.compare(a.auth_id, b.auth_id, isDesc);
        case 'company': return this.compare(a.company, b.company, isDesc);
        case 'updated_at': return this.compare(a.updated_at, b.updated_at, isDesc);
        case 'updated_by': return this.compare(a.updated_by, b.updated_by, isDesc);
        default: return 0;
      }
    });
  }
  compare(a, b, isDesc) {
    return (a < b ? -1 : 1) * (isDesc ? 1 : -1);
  }
}
