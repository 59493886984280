import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { SearchInfo, SearchQueryInfo } from '../../../interface/search.interface';

@Injectable({
  providedIn: 'root'
})
export class SearchFormService {
  searchFormParamsSubject$: BehaviorSubject<SearchQueryInfo>;
  searchFormParams$: Observable<SearchQueryInfo>;
  searchInfo: SearchQueryInfo;
  date: Date;

  constructor() {
    this.date = new Date();
    this.searchInfo = {
      batch_no: '',
      direction_no: '',
      item_cd: '',
      jancode: '',
      user_id: '',
      trace_no: '',
      start_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString(),
      end_date: new Date(this.date.getFullYear() + '/' + String(this.date.getMonth() + 1) + '/' + this.date.getDate()).toISOString()
    };
    this.searchFormParamsSubject$ = new BehaviorSubject(this.searchInfo);
    this.searchFormParams$ = this.searchFormParamsSubject$.asObservable();
  }

  setSearchFormParams(searchInfo: SearchInfo): void {
    this.searchInfo.batch_no = searchInfo.batch_no;
    this.searchInfo.direction_no = searchInfo.direction_no;
    this.searchInfo.item_cd = searchInfo.item_code;
    this.searchInfo.jancode = searchInfo.jancode;
    this.searchInfo.user_id = searchInfo.user_id;
    this.searchInfo.trace_no = searchInfo.trace_no;
    this.searchInfo.start_date = searchInfo.fromDate.toISOString();
    this.searchInfo.end_date = searchInfo.toDate.toISOString();
    this.searchFormParamsSubject$.next(this.searchInfo);
  }
}
