import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-check-result',
  templateUrl: './purchase-result.component.html',
  styleUrls: ['./purchase-result.component.css']
})
export class PurchaseResultComponent implements OnInit {
  constructor(
  ) {}

  ngOnInit() {
  }
}
