import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from '../../component/page-not-found/page-not-found.component';

import { ProgressComponent } from '../../module/progress/progress.component';
import { CheckResultComponent } from '../../module/check-result/check-result.component';
import { TraceResultComponent } from '../../module/trace-result/trace-result.component';
import { RecycleResultComponent } from '../../module/recycle-result/recycle-result.component';
import { PurchaseResultComponent } from '../../module/purchase-result/purchase-result.component';
import { CollationResultComponent } from '../../module/collation-result/collation-result.component';
import { UserDataComponent } from '../../module/user-data/user-data.component';
import { UploadComponent } from '../../module/upload/upload/upload.component';
import { ItemUploadComponent } from '../../module/upload/item-upload/item-upload.component';
import { MessageUploadComponent } from '../../module/upload/message-upload/message-upload.component';
import { RecycleUploadComponent } from '../../module/upload/recycle-upload/recycle-upload.component';
import { ItemListComponent } from '../../module/item-list/item-list.component';
import { MessageListComponent } from '../../module/message-list/message-list.component';
import { IndexComponent } from '../../module/index/index.component';
import { LoginComponent } from '../../module/login/login.component';
import { AuthGuard } from '../../service/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  {
    path: 'index', component: IndexComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'progress', pathMatch: 'full' },
      { path: 'progress', component: ProgressComponent },
      { path: 'check/item', component: CheckResultComponent },
      { path: 'check/trace', component: TraceResultComponent },
      { path: 'check/recycle', component: RecycleResultComponent },
      { path: 'check/purchase', component: PurchaseResultComponent },
      { path: 'check/collation', component: CollationResultComponent },
      { path: 'config', component: UserDataComponent },
      { path: 'upload/direction', component: UploadComponent },
      { path: 'upload/item', component: ItemUploadComponent },
      { path: 'upload/message', component: MessageUploadComponent },
      { path: 'upload/recycle', component: RecycleUploadComponent },
      { path: 'upload/item/list', component: ItemListComponent },
      { path: 'upload/message/list', component: MessageListComponent },
    ]
  },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  exports: [
    RouterModule
  ]
})
export class ContentRoutingModule { }
