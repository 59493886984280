import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../service/title.service';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestService } from '../../../service/request.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginUser } from '../../../../interface/interface';
import { SearchInfo } from '../../../../interface/search.interface';
import { UserInfoService } from '../../../service/user-info.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.css']
})
export class UploadComponent implements OnInit {
  subscriptions: Subscription[];
  searchInfo: SearchInfo;
  date: Date;
  file: any;
  directionNo: string;
  formGroup: FormGroup;
  loginUserInfo: LoginUser;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.userInfoService.userInfo$
        .subscribe((loginUserInfo) => {
          this.loginUserInfo = loginUserInfo;
        })
    );
    this.titleService.setTitle('検品指示CSVファイル取込');
  }

  upload() {
    if (!this.file) {return; }
    const body = this.file;
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Barnet-File-Name': btoa(unescape(encodeURIComponent(this.file.name))),
      'Barnet-User': this.loginUserInfo.user_id
    });
    this.subscriptions.push(
      this.requestService.post('/csv/direction', body, headers)
      .subscribe((response) => {
        if ( response.length !== 0) {
          const errDirectionNo = response.duplicatedDiretionNo.join(`\n`);
          alert(`アップロード処理完了\n成功件数：${response.successCount}\n失敗件数：${response.failedCount}\n重複指示No:\n${errDirectionNo}`);
        }
      })
    );
  }
  delete() {
    this.subscriptions.push(
      this.requestService.get('/check/delete', this.searchInfo)
      .subscribe((response) => {
        if ( response.length !== 0) {
          var confirmText = '削除対象指示情報 指示番号:' + this.searchInfo.direction_no + '\n'
          confirmText += '登録日時：' + response[0].created_at + '\n'
          confirmText += '商品件数：' + response.length + '件\n'
          response.forEach((row) => {
            confirmText += row.item_cd + ' ' + row.item_name + '\n'
          })
          confirmText += `\n削除してよろしいですか？`

          let result = confirm(confirmText);
          if (result) {
            this.requestService.delete('/check/delete', this.searchInfo).subscribe((response) => {
              console.log(response);
              if ( response.rowCount !== 0) {
                alert(`検品実績の削除に成功しました`);
              }
            })
          }
        }
      })
    );
  }
  batch_delete() {
    this.subscriptions.push(
      this.requestService.get('/check/batch/delete', this.searchInfo)
      .subscribe((response) => {
        if ( response.length !== 0) {
          var confirmText = '削除対象指示情報 バッチNo:' + this.searchInfo.batch_no + '\n'
          confirmText += '登録日時：' + response[0].created_at + '\n'
          confirmText += `\n削除してよろしいですか？`

          let result = confirm(confirmText);
          if (result) {
            this.requestService.delete('/check/batch/delete', this.searchInfo).subscribe((response) => {
              console.log(response);
              if ( response.rowCount !== 0) {
                alert(`指示情報の削除に成功しました`);
              }
            })
          }
        }
      })
    );
  }
  getFile(event) {
    this.file = event.target.files[0];
    const csvData = this.file;
  }
  private fieldInit() {
    this.subscriptions = [];
    this.date = new Date();
    this.searchInfo = {
      batch_no: '',
      direction_no: '',
      item_code: '',
      jancode: '',
      user_id: '',
      trace_no: '',
      fromDate: null,
      toDate: null
    };
    this.formGroup = this.formBuilder.group({
      direction_no: this.formBuilder.control(''),
      batch_no: this.formBuilder.control(''),
    });

    this.subscriptions.push(
      this.formGroup.get('direction_no').valueChanges
        .subscribe((value) => {
          this.searchInfo['direction_no'] = value;
        })
    );
    this.subscriptions.push(
      this.formGroup.get('batch_no').valueChanges
        .subscribe((value) => {
          this.searchInfo['batch_no'] = value;
        })
    );
  }
}
