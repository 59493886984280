import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-trace-result',
  templateUrl: './trace-result.component.html',
  styleUrls: ['./trace-result.component.css']
})
export class TraceResultComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
