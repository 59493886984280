import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MessageSearchInfo } from '../../../../interface/search.interface';
import { RequestService } from '../../../service/request.service';
import { MatTableDataSource, MatSort, Sort } from '@angular/material';
import { TitleService } from '../../../service/title.service';
import { Subscription } from 'rxjs';
import { MessageFormService } from '../../../service/search-form/message-form.service';
import { subscribeOn, debounceTime, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-item-table',
  templateUrl: './message-table.component.html',
  styleUrls: ['./message-table.component.css']
})
export class MessageTableComponent implements OnInit {
  subscriptions: Subscription[];
  columns: string[];
  messageInfo: MessageSearchInfo;
  casheData: MessageSearchInfo[];
  dataSource: MatTableDataSource<MessageSearchInfo>;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private messageFormSevice: MessageFormService
    ) { }

  ngOnInit() {
    this.subscriptions = [];
    this.setColumns();
    this.subscriptions.push(
      this.messageFormSevice.messageFormParams$
        .pipe(
          flatMap(query => this.requestService.get('/check/message/row/result/', query))
        )
        .subscribe(
          res => {
          this.casheData = Array.isArray(res) ? res : [];
          this.dataSource = new MatTableDataSource(this.casheData);
          this.dataSource.sort = this.sort;
          },
          error => {
            console.log(`照合用メッセージマスタテーブル生成 : ${error}`);
          },
          () => {
            console.log('照合用メッセージマスタテーブル生成 : 成功');
          }
        )
      );
    this.titleService.setTitle('照合用メッセージマスタ情報一覧');
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  setColumns(): void {
    this.columns = [
      'jancode',
      'alert_message',
      'created_at'
    ];
  }
}
