import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatFormFieldControl, DateAdapter, NativeDateAdapter, MatDialog } from '@angular/material';
import { FormGroup, FormBuilder } from '@angular/forms';
import { PurchaseQueryInfo } from '../../../../interface/search.interface';
import { filter, debounceTime, skip, flatMap, tap, publish, refCount } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { RequestService } from '../../../service/request.service';
import { PurchaseSearchFormService } from '../../../service/search-form/purchase-search-form.service';
import { TitleService } from '../../../service/title.service';
import { PurchaseDialogService } from '../../../service/search-form/purchase-dialog.service';
import { PurchaseItemDialogComponent } from '../../dialog/purchase-form-dialog/purchase-form-dialog.component';
import { HttpHeaders } from '@angular/common/http';

export class MyDateAdapter extends NativeDateAdapter {
  getDateNames(): string[] {
    const dateNames: string[] = [];
    for (let i = 0; i < 31; i++) {
      dateNames[i] = String(i + 1);
    }
    return dateNames;
  }
}

@ViewChild(MatFormFieldControl)

@Component({
  selector: 'app-purchase-search-form',
  templateUrl: './purchase-search-form.component.html',
  styleUrls: ['./purchase-search-form.component.css'],
  providers: [
    { provide: DateAdapter, useClass: MyDateAdapter }
  ]
})

export class PurchaseSearchFormComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  formGroup: FormGroup;
  searchInfo: PurchaseQueryInfo;
  now: Date = new Date();
  arrival_start_date: Date;
  arrival_end_date: Date;
  start_date: Date;
  end_date: Date;
  minDate: Date;
  fromMaxDate: Date;
  maxDate: Date;
  csvQuery: string;

  constructor(
    private requestService: RequestService,
    public matDialog: MatDialog,
    private purchaseDialogService: PurchaseDialogService,
    private purchaseSearchFormService: PurchaseSearchFormService,
    private formBuilder: FormBuilder,
    private dateAdapter: DateAdapter<NativeDateAdapter>,
    private titleService: TitleService
  ) { }


  ngOnInit() {
    //初期化
    this.fieldInit();

    this.subscriptions.push(
      this.titleService.title$
        .pipe(
          debounceTime(100),
          filter(title => title === 'シリアルナンバー記録履歴'),
          skip(1)
        )
        .subscribe(
          (title) => {
            this.purchaseSearchFormService.setPurchaseFormParams(this.searchInfo);
          },
          error => {
            console.log(error);
          },
          () => {
            console.log('シリアルナンバー記録履歴検索設定：成功');
          }
        )
    );

    Object.keys(this.searchInfo).forEach((key) => {
      this.subscriptions.push(
        this.formGroup.get(key).valueChanges
          .subscribe((value) => {
            if (key === 'start_date') {
              this.start_date = value;
              if (this.formGroup.get('start_date').value > this.formGroup.get('end_date').value) {
                this.fromMaxDate = value;
                this.formGroup.get('start_date').setValue(this.end_date);
                this.formGroup.get('end_date').setValue(this.start_date);
                this.searchInfo.start_date = this.end_date.toISOString();
                this.searchInfo.end_date = this.start_date.toISOString();
              } else {
                this.fromMaxDate = this.formGroup.get('end_date').value;
                this.searchInfo.start_date = this.formGroup.get('start_date').value.toISOString();
                this.searchInfo.end_date = this.formGroup.get('end_date').value.toISOString();
              }
            }
            if (key === 'end_date') {
              this.end_date = value;
              if (this.formGroup.get('start_date').value > this.formGroup.get('end_date').value) {
                this.fromMaxDate = value;
                this.formGroup.get('start_date').setValue(this.end_date);
                this.formGroup.get('end_date').setValue(this.start_date);
                this.searchInfo.start_date = this.end_date.toISOString();
                this.searchInfo.end_date = this.start_date.toISOString();
              } else {
                this.fromMaxDate = this.formGroup.get('end_date').value;
                this.searchInfo.start_date = this.formGroup.get('start_date').value.toISOString();
                this.searchInfo.end_date = this.formGroup.get('end_date').value.toISOString();
              }
            }
            this.searchInfo[key] = value;
          })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
    this.searchInfo = {
      slip_number: '',
      arrival_start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      arrival_end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      item_code: '',
      serial_number: '',
      user_id: '',
      start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString()
    };
    this.search();
  }

  search() {
    this.purchaseSearchFormService.setPurchaseFormParams(this.searchInfo);
  }

  download() {
    const csvNow = new Date();
    const a = document.createElement('a');
    const year = csvNow.getFullYear();
    const month = ('0' + String(csvNow.getMonth() + 1)).slice(-2);
    const date = ('0' + csvNow.getDate()).slice(-2);
    const hour = ('0' + csvNow.getHours()).slice(-2);
    const minutes = ('0' + csvNow.getMinutes()).slice(-2);
    a.download = year + month + date + '_' + hour + minutes + '.csv';
    // tslint:disable-next-line:max-line-length
    a.href = `/api/v1/purchase/row/result/csv?slip_number=${this.searchInfo.slip_number}&item_code=${this.searchInfo.item_code}&serial_number=${this.searchInfo.serial_number}&arrival_start_date=${this.searchInfo.arrival_start_date}&arrival_end_date=${this.searchInfo.arrival_end_date}&user_id=${this.searchInfo.user_id}&start_date=${this.searchInfo.start_date}&end_date=${this.searchInfo.end_date}`;
    a.click();
    a.remove();
  }

  createForm(): void {
    this.purchaseDialogService.setPurchaseDialogInit(
      {
        id: '',
        slip_number: '',
        supplier_name: '',
        arrival_date: this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate(),
        item_code: '',
        serial_number: '',
        remarks: ''
      });
    const dialog = this.matDialog.open(PurchaseItemDialogComponent,
      {
        'height': '75vh',
        'width': '50vw',
        'disableClose': false
      }
    );
    const purchaseItem$ = dialog.afterClosed()
      .pipe(
        tap(result => console.log(result)),
        publish(),
        refCount()
      );
    this.subscriptions.push(
      purchaseItem$
        .pipe(
          filter(result => result),
          tap(),
          flatMap(result => this.requestService.post('/purchase/item', result, new HttpHeaders({ 'Content-Type': 'application/json' })))
        )
        .subscribe(
          res => {
            if (res.length !== 0) {
              this.titleService.setTitle('シリアルナンバー記録履歴');
              alert('登録が完了しました');
            }
            this.search();
          }
        )
    );
  }

  private fieldInit() {
    this.start_date = new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate());
    this.end_date = new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate());
    this.arrival_start_date = new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate());
    this.arrival_end_date = new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate());
    this.minDate = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate() - 90);
    this.maxDate = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    this.fromMaxDate = new Date(this.now.getFullYear(), this.now.getMonth(), this.now.getDate());
    this.dateAdapter.setLocale('ja');
    this.searchInfo = {
      slip_number: '',
      arrival_start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      arrival_end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      item_code: '',
      serial_number: '',
      user_id: '',
      start_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString(),
      end_date: new Date(this.now.getFullYear() + '/' + String(this.now.getMonth() + 1) + '/' + this.now.getDate()).toISOString()
    };
    this.formGroup = this.formBuilder.group({
      slip_number: this.formBuilder.control(''),
      arrival_start_date: this.formBuilder.control({
        value: this.arrival_start_date,
        disabled: true
      }),
      arrival_end_date: this.formBuilder.control({
        value: this.arrival_end_date,
        disabled: true
      }),
      item_code: this.formBuilder.control(''),
      serial_number: this.formBuilder.control(''),
      user_id: this.formBuilder.control(''),
      start_date: this.formBuilder.control({
        value: this.start_date,
        disabled: true
      }),
      end_date: this.formBuilder.control({
        value: this.end_date,
        disabled: true
      })
    });
  }
}

