import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { FormService } from '../../../service/form.service';
import { User } from '../../../../interface/interface';
import { RequestService } from '../../../service/request.service';
import { compareValidator } from '../../../compare-validator.directive';

@Component({
  // angularMaterialでdialog表示する際selector名を変更すると表示がおかしくなるので注意
  selector: 'app-user-form-dialog',
  templateUrl: './user-form-dialog.component.html',
  styleUrls: ['./user-form-dialog.component.css']
})

/**
 * UserTableComponentから呼び出し
 * 担当者マスタを更新する情報を入力するフォームダイアログ
 * dialogを閉じた時,closeメソッドの引数が登録データとしてUserTableComponentに渡される
 */
export class UserFormDialogComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[];
  // _subscriptions: Subscription[];

  // insert: true, update: false
  formFlg: boolean;
  isLinear: boolean;
  userIdGroup: FormGroup;
  nameGroup: FormGroup;
  passGroup: FormGroup;
  authGroup: FormGroup;
  companyGroup: FormGroup;
  memoGroup: FormGroup;
  user: User;
  hide: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialogRef: MatDialogRef<UserFormDialogComponent>,
    private formBuilder: FormBuilder,
    private formService: FormService,
    private requestService: RequestService
  ) {}

  ngOnInit() {
    this.hide = true;
    this.subscriptions = [];
    this.isLinear = false;
    this.user = {
      user_id: '',
      name: '',
      pass: '',
      _pass: '',
      auth_id: '',
      company: '',
      memo: ''
    };

    // 新規登録か更新かのフラグ取得
    this.subscriptions.push(
      this.formService.userFormInit$
        .subscribe((row) => {
        this.formFlg = row.formFlg;
        this.user.user_id = row.user_id;
        this.user.name = row.name;
        this.user.auth_id = row.auth_id;
        this.user.company = row.company;
      })
    );

    // formの初期値を生成
    this.userIdGroup = this.formBuilder.group({
      user_id: this.formBuilder.control(
        this.formFlg ? { value: this.user.user_id, disabled: true } : '', Validators.compose([
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z_¥-¥.]*$/),
          Validators.maxLength(16)
        ])
      )
    });
    this.subscriptions.push(
      this.userIdGroup.get('user_id').valueChanges
        .subscribe((value) => {
          this.user['user_id'] = value;
        })
    );
    this.nameGroup = this.formBuilder.group({
      name: this.formBuilder.control(
        this.formFlg ? this.user.name : '', Validators.compose([
          Validators.required,
          Validators.maxLength(32)
        ])
      )
    });
    this.subscriptions.push(
      this.nameGroup.get('name').valueChanges
        .subscribe((value) => {
          this.user['name'] = value;
        })
    );
    this.passGroup = this.formBuilder.group({
      pass: this.formBuilder.control('', Validators.compose(
        this.formFlg ?
        [
          Validators.pattern(/^[0-9a-zA-Z_¥-¥.]*$/),
          Validators.maxLength(16),
        ] : [
          Validators.required,
          Validators.pattern(/^[0-9a-zA-Z_¥-¥.]*$/),
          Validators.maxLength(16)
        ]
      )),
      _pass: this.formBuilder.control('', Validators.compose([
        Validators.required,
        Validators.pattern(/^[0-9a-zA-Z_¥-¥.]*$/),
        Validators.maxLength(16),
        compareValidator('pass')
      ]))
    });
    this.subscriptions.push(
      this.passGroup.get('pass').valueChanges
        .subscribe((value) => {
          this.user['pass'] = value;
          if (value.length === 0) { this.passGroup.get('_pass').setValue(''); }
        })
    );
    this.subscriptions.push(
      this.passGroup.get('_pass').valueChanges
        .subscribe((value) => {
          this.user['_pass'] = value;
        })
    );
    this.companyGroup = this.formBuilder.group({
      company: this.formBuilder.control(
        this.formFlg ? this.user.company : '', Validators.maxLength(32)
      )
    });
    this.subscriptions.push(
      this.companyGroup.get('company').valueChanges
        .subscribe((value) => {
          this.user['company'] = value;
        })
    );
    this.memoGroup = this.formBuilder.group({
      memo: this.formBuilder.control(
        this.formFlg ? this.user.memo : '', Validators.maxLength(256)
      )
    });
    if (this.formFlg) {
      this.subscriptions.push(
        this.requestService.get('/user/' + this.user.user_id, {})
          .subscribe((userInfo) => {
            this.memoGroup.get('memo').setValue(userInfo.memo);
          })
        );
    }
    this.subscriptions.push(
      this.memoGroup.get('memo').valueChanges
        .subscribe((value) => {
          this.user['memo'] = value;
        })
    );
    this.authGroup = this.formBuilder.group({
      auth_id: this.formBuilder.control(
        this.formFlg ? this.user.auth_id : 'User'
      )
    });
    this.subscriptions.push(
      this.authGroup.get('auth_id').valueChanges
        .subscribe((value) => {
          this.user['auth_id'] = value;
        })
    );

    // // 編集
    // Object.keys(this.user).forEach((key) => {
    //   this.subscriptions.push(
    //     this.formGroup.get(key).valueChanges
    //       .subscribe((value) => {
    //         this.user[key] = value;
    //       })
    //   );
    // });
  }
  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  cancelDialog() {
    this.matDialogRef.close();
  }
  okDialog() {
    this.matDialogRef.close({
      user_id: this.user.user_id || '',
      name: this.user.name || '',
      pass: this.user.pass || '',
      auth_id: this.user.auth_id,
      company: this.user.company || '',
      memo: this.user.memo || ''
    });
  }
}
