import { NgModule } from '@angular/core';
import { UserDataComponent } from './user-data.component';
import { SharedModule } from '../shared/shared.module';
import { UserTableComponent } from '../../component/user-table/user-table.component';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material';
import { UserFormDialogComponent } from '../../component/dialog/user-form-dialog/user-form-dialog.component';
import { AuthPipe } from '../../pipe/auth.pipe';


@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    UserDataComponent,
    UserTableComponent
  ],
  declarations: [
    UserDataComponent,
    UserTableComponent,
    UserFormDialogComponent,
    AuthPipe
  ],
  providers: [
  ],
  entryComponents: [
    UserFormDialogComponent
  ]
})
export class UserDataModule { }
