import { Directive, Input } from '@angular/core';
import { Validator, AbstractControl, ValidationErrors, NG_VALIDATORS, ValidatorFn } from '../../node_modules/@angular/forms';
import { Subscription } from '../../node_modules/rxjs';

export function compareValidator(controlNameToCompare: string): ValidatorFn {
  return (ac: AbstractControl): ValidationErrors | null => {
    if (ac.value === null || ac.value.length === 0) {
      return null;
    }
    const controlToCompare = ac.root.get(controlNameToCompare);
    if (controlToCompare) {
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
        ac.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }
    return controlToCompare && controlToCompare.value !== ac.value ? {'compare': true} : null;
  };
}

@Directive({
  selector: '[appCompare]',
  providers: [{ provide: NG_VALIDATORS, useExisting: CompareValidatorDirective, multi: true }]
})
export class CompareValidatorDirective implements Validator {
// tslint:disable-next-line:no-input-rename
@Input('compare') controlNameToCompare: string;

  validate(ac: AbstractControl): ValidationErrors | null {
    if (ac.value === null || ac.value.length === 0) {
      return null;
    }
    const controlToCompare = ac.root.get(this.controlNameToCompare);
    if (controlToCompare) {
      const subscription: Subscription = controlToCompare.valueChanges.subscribe(() => {
        ac.updateValueAndValidity();
        subscription.unsubscribe();
      });
    }
    return controlToCompare && controlToCompare.value !== ac.value ? {'compare': true} : null;
  }
}
