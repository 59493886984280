import { NgModule } from '@angular/core';

import {
  MatButtonModule,
  MatIconModule,
  MatToolbarModule,
  MatCardModule,
  MatSidenavModule,
  MatTableModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatStepperModule,
  MatSelectModule,
  MatExpansionModule,
  MatGridListModule,
  MatProgressSpinnerModule,
  MatCheckboxModule,
 } from '@angular/material';

const modules = [
  MatButtonModule,
  MatIconModule,
  MatToolbarModule,
  MatCardModule,
  MatSidenavModule,
  MatTableModule,
  MatSortModule,
  MatFormFieldModule,
  MatInputModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatDialogModule,
  MatStepperModule,
  MatSelectModule,
  MatExpansionModule,
  MatGridListModule,
  MatProgressSpinnerModule,
  MatCheckboxModule
];

@NgModule({
  imports: [modules],
  exports: [modules],
  declarations: []
})
export class AngularMaterialModule { }
