import { NgModule } from '@angular/core';
import { RecycleUploadComponent } from './recycle-upload.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    RecycleUploadComponent
  ],
  declarations: [
    RecycleUploadComponent
  ]
})
export class RecycleUploadModule { }
