import { NgModule } from '@angular/core';
import { UploadComponent } from './upload.component';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
  imports: [
    SharedModule
  ],
  exports: [
    SharedModule,
    UploadComponent
  ],
  declarations: [
    UploadComponent
  ]
})
export class UploadModule { }
