import { Component, OnInit } from '@angular/core';
import { TitleService } from '../../../service/title.service';
import { HttpClientModule, HttpClient, HttpHeaders } from '@angular/common/http';
import { RequestService } from '../../../service/request.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription, Observable, of } from 'rxjs';
import { LoginUser } from '../../../../interface/interface';
import { UserInfoService } from '../../../service/user-info.service';

@Component({
  selector: 'app-upload',
  templateUrl: './message-upload.component.html',
  styleUrls: ['./message-upload.component.css']
})
export class MessageUploadComponent implements OnInit {
  subscriptions: Subscription[];
  file: any;
  loginUserInfo: LoginUser;

  constructor(
    private requestService: RequestService,
    private titleService: TitleService,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private userInfoService: UserInfoService
  ) {}

  ngOnInit() {
    this.subscriptions = [];
    this.subscriptions.push(
      this.userInfoService.userInfo$
        .subscribe((loginUserInfo) => {
          this.loginUserInfo = loginUserInfo;
        })
    );
    this.titleService.setTitle('照合用メッセージマスタ管理');
  }

  upload() {
    if (!this.file) { return; }
    const body = this.file;
    const headers = new HttpHeaders({
      'Content-Type': 'application/octet-stream',
      'Barnet-File-Name': btoa(unescape(encodeURIComponent(this.file.name))),
      'Barnet-User': this.loginUserInfo.user_id
    });
    this.subscriptions.push(
      this.requestService.post('/csv/message', body, headers)
      .subscribe((response) => {
        if ( response.length !== 0) {
          alert('アップロード成功');
        }
      })
    );
  }

  download() {
    const a = document.createElement('a');
    const now = new Date();
    a.download = 'メッセージマスタ_' + now.getFullYear() + (now.getMonth()+ 1) + now.getDate() + '.csv';
    a.href = `/api/v1/csv/message`;
    a.click();
    a.remove();
  }

  getFile(event) {
    this.file = event.target.files[0];
  }
}
